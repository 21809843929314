import createHmac from 'create-hmac';
// import createHmac from 'crypto-js/hmac-sha256';

import { getRandomString } from './text';

export function getHashKeys(string, salt = null, fakeEnv = null) {
    // process.env.NODE_ENV !== 'production' && console.log('getHashKeys ::: Start', string, salt);
    if(!salt) {
        salt = getRandomString();
    }
    // process.env.NODE_ENV !== 'production' && console.log('getHashKeys ::: Init', `${fakeEnv || process.env.NODE_ENV}${salt}${string}`, salt);
    const key = createHmac('sha256', salt)
    // const key = crypto.createHmac('sha256', salt)
        .update(`${fakeEnv || process.env.NODE_ENV}${salt}${string}`)
        .digest('hex');

    // process.env.NODE_ENV !== 'production' && console.log('getHashKeys ::: End', key);

    return {
        salt,
        key,
    };
}
