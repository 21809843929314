import countryToCurrency from 'country-to-currency';
import callApi, { callExternalApi } from '../../util/apiCaller';

import { addError } from '../Error/ErrorActions';
import { getLocalizationRequest } from '../App/AppActions';

// Export Constants
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SET_RATES = 'SET_RATES';

export const defaultCurrency = 'EUR';
export const CURRENCY_SET_MODE = 'intl'; // 'ip' || 'intl'

export function setCurrencyRequest(newCurrency) {
    return dispatch => {
        if(newCurrency && isCurrencyEnabled(newCurrency)) {
            return callApi(`user/preference/currency/${newCurrency}`, 'post').then(res => {
                return res;
            }).catch(error => {
                // dispatch(addError(error));
            });
        }
    };
}

export function getCurrencyRatesRequest() {
    return dispatch => {
        const enabledCurrencies = getEnabledCurrencies();
        return callApi(`currencies?base=${defaultCurrency}&symbols=${enabledCurrencies.filter(currency => currency !== defaultCurrency).join(',')}`).then(res => {
            dispatch(setCurrencyRates(res.rates));
            return res;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getEnabledCurrencies() {
    return ['EUR', 'GBP', 'USD', 'CAD'];
}

export function isCurrencyEnabled(currency) {
    return getEnabledCurrencies().includes(currency);
}

export function getCurrencySymbol(currency) {
    switch(currency) {
        case 'GBP':
            return '£';

        case 'USD':
            return '$US';

        case 'CAD':
            return '$CA';

        case 'EUR':
            return '€';

        default:
            return currency;
    }
}

export function initCurrency(user, mode = null) {
    return dispatch => {
        if(user && user.currency) {
            return dispatch(switchCurrency(user.currency));
        }
        // console.log(Intl.DateTimeFormat().resolvedOptions());
        let setDefaultCurrencyPromise = () => Promise.resolve(defaultCurrency);
        switch(mode || CURRENCY_SET_MODE) {
            case 'ip':
                setDefaultCurrencyPromise = () => new Promise((resolve, reject) => {
                    let currency = 'USD';
                    // callExternalApi('http://ip-api.com/json').then(result => {
                    //     // console.log(result);
                    //     if(result.currency) {
                    //         currency = result.currency;
                    //     } else if(result.countryCode) {
                    //         currency = countryToCurrency[result.countryCode];
                    //     } else if(result.timezone) {
                    //         currency = getCurrencyFromTimezone(result.timezone);
                    //     }
                    //     resolve(currency);
                    // }).catch(err => {
                    //     console.error(err);
                    //     dispatch(initCurrency(user, 'intl'));
                    // });
                    getLocalizationRequest().then(({ countryCode }) => {
                        console.log('Country', countryCode);
                        currency = countryToCurrency[countryCode];
                    });
                });
                break;

            case 'intl':
            default: {
                setDefaultCurrencyPromise = () => Promise.resolve(getCurrencyFromTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));
            }
        }
        setDefaultCurrencyPromise().then(currency => {
            return dispatch(switchCurrency(currency));
        });
    };
}

function getCurrencyFromTimezone(timezone, currency = defaultCurrency) {
    const [continent, city] = timezone.toLowerCase().split('/');
    if(['london'].includes(city)) {
        currency = 'GBP';
    } else if(['canada'].includes(continent)) {
        currency = 'CAD';
    } else if(['europe'].includes(continent) || ['martinique', 'guadeloupe', 'guyana', 'reunion'].includes(city)) {
        currency = 'EUR';
    }
    return currency;
}

export function getCurrentCurrency(store) {
    return store.currency.currency;
}

export function switchCurrency(newCurrency) {
    if(newCurrency && isCurrencyEnabled(newCurrency)) {
        return {
            type: SWITCH_CURRENCY,
            currency: newCurrency,
        };
    }
    return {};
}

export function setCurrencyRates(rates) {
    return {
        type: SET_RATES,
        rates,
    };
}
