import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import config from '../../config';

export default function Loader(props) {
    const style = {
        minHeight: '300px',
        textAlign: 'center',
        margin: '150px auto 0',
        padding: '50px',
    };
    return (
        <div className="w-100 loader-wrapper" style={style}>
            {!props.hideLogo && <img src={`${config.staticUrl}assets/images/logo-monlivresms-2.png`} width="150" alt={config.application.name} />}<br /><br />
            <img src={`${config.staticUrl}assets/images/ellipsis.svg`} role="presentation" className="ellipsis rotate" height="20" style={{ maxWidth: '80%' }} />
        </div>
    );
}

Loader.propTypes = {
    hideLogo: PropTypes.bool,
};
