import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, ButtonDropdown, Button, Badge, Container } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import Helmet from 'react-helmet';
// import io from 'socket.io-client';

import config from '../../../../config';

import { capitalizeFirstLetter } from '../../../../util/text';

import { checkVersionRequest, initSocket, sendSocket, initPersistedData, initApp } from '../../AppActions';
import { initLanguage, getCountryFromLanguage } from '../../../Intl/IntlActions';
import { displayErrors, removeError } from '../../../Error/ErrorActions';
import { logoutUser, getUserPreference } from '../../../User/UserActions';
// import socketListener from '../../../../socketListener';

import { enabledLanguages } from '../../../../../Intl/setup';

import withRouter from '../../../../components/Router/WithRouter';
import AppLogo from '../AppLogo';
import Menu from '../Menu/Menu';
import Flag from '../../../../components/Flag/Flag';
import UserProfileImage from '../../../User/components/profile/UserProfileImage';
import { getCurrencySymbol, getCurrentCurrency, getEnabledCurrencies, initCurrency, setCurrencyRequest, switchCurrency } from '../../../Currency/CurrencyActions';

// const socket = io(config.ws);

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            dropdownLanguageOpen: false,
            dropdownNotificationsOpen: false,
            dropdownCurrencyOpen: false,
        };

        this.appVersionInterval = null;
    }

    componentDidMount() {
        this.props.dispatch(initPersistedData());
        // this.props.dispatch(initSocket(socket));
        // this.props.user && socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);

        // this.switchLanguage(null);

        this.props.dispatch(initApp());

        this.props.dispatch(initCurrency());
        this.props.dispatch(checkVersionRequest());
        this.appVersionInterval = setInterval(() => {
            this.props.dispatch(checkVersionRequest());
        }, 3 * 60 * 60 * 1000); // 3h
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }

        // Update app language from user preferences
        if(!prevProps.user && this.props.user) {
            this.props.intl.locale !== getUserPreference(this.props.user, 'language') && this.switchLanguage(getUserPreference(this.props.user, 'language'));
            // this.props.user && socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);
            this.props.dispatch(initCurrency(this.props.user));
        }

        // Redirect app if triggered by other component
        if(prevProps.appRedirect !== this.props.appRedirect) {
            this.props.navigate(this.props.appRedirect);
        }

        // Display or remove errors
        if(prevProps.errors.length !== this.props.errors.length) {
            this.props.errors.forEach(error => {
                if(error && error.type && error.message) {
                    displayErrors(error.type, this.props.intl.messages[`alert${error.message}`] || error.message);
                }
                this.props.dispatch(removeError(error.message));
            });
        }
    }

    componentWillUnmount() {
        this.appVersionInterval && clearInterval(this.appVersionInterval);
    }

    // handleLogout = () => {
    //     // socket.emit('userDisconnection');
    //     this.props.dispatch(logoutUser(true));
    //     this.props.navigate('/');
    // };

    isLoggedIn = () => {
        return this.props.user && this.props.user._id;
    };

    switchLanguage = lang => {
        this.props.dispatch(initLanguage(lang, this.props.user)).then(lang => {});
    };

    switchCurrency = currency => {
        this.props.dispatch(setCurrencyRequest(currency));
        this.props.dispatch(switchCurrency(currency));
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    toggleDropdownLanguage = () => {
        this.setState({
            dropdownLanguageOpen: !this.state.dropdownLanguageOpen,
        });
    };

    toggleDropdownCurrency = () => {
        this.setState({
            dropdownCurrencyOpen: !this.state.dropdownCurrencyOpen,
        });
    };

    // toggleDropdownNotifications = () => {
    //     this.setState({
    //         dropdownNotificationsOpen: !this.state.dropdownNotificationsOpen,
    //     });
    // };

    renderLanguages() {
        const languageNodes = enabledLanguages.filter(locale => locale !== this.props.intl.locale).map(
            lang => <DropdownItem key={lang} size="sm" onClick={event => this.switchLanguage(lang)}><span className={lang === this.props.intl.locale ? 'selected' : ''}><Flag country={getCountryFromLanguage(lang)} /></span></DropdownItem>,
        );
        return (
            <ButtonDropdown isOpen={this.state.dropdownLanguageOpen} toggle={() => this.toggleDropdownLanguage()} className="text-dark">
                <DropdownToggle nav caret className="text-dark">
                    <Flag country={getCountryFromLanguage(this.props.intl.locale)} />
                </DropdownToggle>
                <DropdownMenu className="text-center">{languageNodes}</DropdownMenu>
            </ButtonDropdown>
        );
    }

    renderCurrencies() {
        const currencyNodes = getEnabledCurrencies().filter(currency => currency !== this.props.currency).map(
            currency => <DropdownItem key={currency} size="sm" onClick={event => this.switchCurrency(currency)}><span className={currency === this.props.currency ? 'selected' : ''}>{getCurrencySymbol(currency)}</span></DropdownItem>,
        );
        return (
            <ButtonDropdown isOpen={this.state.dropdownCurrencyOpen} toggle={() => this.toggleDropdownCurrency()} className="text-dark">
                <DropdownToggle nav caret className="text-dark">
                    {getCurrencySymbol(this.props.currency)}
                </DropdownToggle>
                <DropdownMenu className="text-center">{currencyNodes}</DropdownMenu>
            </ButtonDropdown>
        );
    }

    render() {
        return (
            <div className="header-wrapper">
                <Container fluid="xl">
                    <Navbar color="" light expand="md" className={classnames('navbar-expand-sm')}>
                        <NavbarBrand href="https://www.monlivresms.com/">
                            <AppLogo style={{ height: '70px' }} />
                        </NavbarBrand>

                        {this.isLoggedIn() ? <Collapse isOpen={this.state.isOpen} navbar className="justify-content-center"><Menu /></Collapse> : null}

                        <Nav className="ml-auto">
                            <NavItem>
                                {this.renderLanguages()}
                            </NavItem>
                            <NavItem>
                                {this.renderCurrencies()}
                            </NavItem>
                            {this.isLoggedIn() && (
                                <NavItem>
                                    <Link to="/user/profile" className="d-block text-dark"><FontAwesome name="user-circle" size="2x" /></Link>
                                </NavItem>
                            )}
                        </Nav>

                        <NavbarToggler onClick={this.toggle} />
                    </Navbar>
                </Container>
            </div>
        );
    }
}
// <FormattedMessage id="accountMy" defaultMessage="My account" /> ({`${this.props.user.firstName} ${this.props.user.lastName}`})

function mapStateToProps(store, props) {
    return {
        errors: store.error.data,
        user: store.users.user,
        appRedirect: store.app.redirect,
        currency: getCurrentCurrency(store),
    };
}

Header.defaultProps = {
    user: null,
    appRedirect: '',
    currency: 'EUR',
};
Header.propTypes = {
    dispatch: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    user: PropTypes.object,
    appRedirect: PropTypes.string,
    currency: PropTypes.string,
};

export default connect(mapStateToProps)(withRouter(injectIntl(Header)));
