import moment from 'moment';

import callApi, { buildQuery } from '../../util/apiCaller';

import { sequence } from '../../util/promises';
import { blobToFile, uploadFile, getFileContent } from '../../util/file';

import { transformCsvToJson, CONVERSATION_COVER_COLORS, CONVERSATION_PARTICIPANT_COLORS } from './BookActions';
import { getUserId } from '../User/UserActions';
import { setIsFetching, FETCH_LIMIT } from '../App/AppActions';
import { addError } from '../Error/ErrorActions';
import { getDefaultLanguages } from '../Intl/IntlActions';
import { notifyError } from '../../components/Error/ErrorBoundary';

import * as instagramProvider from './providers/ConversationProviderInstagram';
import * as messengerProvider from './providers/ConversationProviderMessenger';
import * as telegramProvider from './providers/ConversationProviderTelegram';
import * as snapchatProvider from './providers/ConversationProviderSnapchat';
import * as whatsappProvider from './providers/ConversationProviderWhatsapp';

// Export Constants
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
export const SET_CONVERSATION_MESSAGES = 'SET_CONVERSATION_MESSAGES';
export const SET_BOOK_GENERATION = 'SET_BOOK_GENERATION';
export const SET_TUTORIAL = 'SET_TUTORIAL';

export const CONVERSATION_STATUS_PENDING = 'pending';
export const CONVERSATION_STATUS_DONE = 'done';

export const MESSAGES_BLOCK_COUNT = 10000;
export const DATE_FORMAT_OUTPUT = 'YYYY-MM-DD HH:mm:00Z';
export const MESSAGE_SYSTEM_SENDER_NAME = '___SYSTEM___';
export const MESSAGE_ATTACHMENTS_SEPARATOR = '|||'; // separtor for multiple attachements on csv
export const MESSAGE_ATTACHMENT_VIEWER_DATA_SEPARATOR = '---';
export const CONVERSATION_ARCHIVE_EXCLUDED_FILES_REGEX = [/(.*)(.enc){1}/i];

export const CONVERSATION_PREVIEW_PAGES_COUNT = 25;

const COLLECTOR_UNIPILE_SPECS = {
    name: 'unipile',
    code: 'connexion',
    isHidden: true,
};

export const CONVERSATIONS_EXTRACTROR_TYPES = {
    'android': {
        steps: ['intro'],
        disableNextStep: true,
    },
    'instagram': {
        steps: ['intro', 'provider', 'file', 'selection', 'options', 'agreement'],
        collectors: [
            {
                name: 'archive',
                helper: instagramProvider,
                fileImportAccept: 'application/zip,application/x-zip,.zip,.json',
                // messagesFileRegex: /^(messages\.json)/i,
                messagesFileRegex: /message[s]?_[0-9]*.json/i, // New archive from Instagram/Messenger fusion in 2021
                isDefault: true,
                sort: 1,
            },
            {
                ...COLLECTOR_UNIPILE_SPECS,
                sort: 2,
            },
        ],
    },
    'ios': {
        steps: ['intro'],
        disableNextStep: true,
    },
    'messenger': {
        steps: ['intro', 'provider', 'file', 'selection', 'options', 'agreement'],
        collectors: [
            {
                name: 'archive',
                helper: messengerProvider,
                fileImportAccept: 'application/zip,application/x-zip,.zip,.json',
                messagesFileRegex: /(?!((archived_threads\/)|(message_requests\/)))message[s]?_[0-9]*.json/i,
                isDefault: true,
                sort: 1,
            },
            {
                ...COLLECTOR_UNIPILE_SPECS,
                sort: 2,
            },
        ],
    },
    'snapchat': {
        steps: ['intro', 'provider', 'file', 'selection', 'options', 'agreement'],
        collectors: [
            {
                name: 'archive',
                helper: snapchatProvider,
                fileImportAccept: 'application/zip,application/x-zip,.zip,.html',
                messagesFileRegex: /subpage_.*(\.html)/i,
                // forceWithImages: true,
                isDefault: true,
                sort: 1,
            },
        ],
    },
    'telegram': {
        steps: ['intro', 'provider', 'file', 'selection', 'options', 'agreement'],
        collectors: [
            {
                name: 'archive',
                helper: telegramProvider,
                fileImportAccept: 'application/zip,application/x-zip,.zip,.html',
                messagesFileRegex: /.*(\.html)/i,
                // forceWithImages: true,
                isDefault: true,
                sort: 1,
            },
        ],
    },
    'whatsapp': {
        steps: ['intro', 'provider', 'file', 'options', 'agreement'],
        collectors: [
            {
                name: 'archive',
                helper: whatsappProvider,
                fileImportAccept: 'application/zip,application/x-zip,.zip,.txt',
                messagesFileRegex: /.*(\.txt)/i,
                // forceWithImages: true,
                isDefault: true,
                sort: 1,
            },
            {
                ...COLLECTOR_UNIPILE_SPECS,
                steps: ['intro', 'provider', 'selection', 'options', 'agreement'],
                sort: 2,
            },
        ],
    },
};

export const CONVERSATION_EDIT_STEPS = ['start', 'cover', 'participants', 'dates', 'preview', 'success'];

export const CONVERSATION_FLAGS = {
    missingMedias: 'MISSING_MEDIAS',
};

export const TUTORIAL_VERSION = 1;

export function getConversationsPageRequest(page = 1, pageSize = FETCH_LIMIT, filters = {}, sorter = {}) {
    return dispatch => {
        return callApi(`conversations?all=1&start=${(page - 1) * pageSize}&limit=${pageSize}&${buildQuery(filters, 'filters')}&${buildQuery(sorter, 'sorter')}`).then(res => {
            res.conversations && dispatch(setConversations(res.conversations || []));
            return {
                ...res,
                isEnd: (res.conversations || []).length < pageSize,
            };
        }).catch(err => {
            return null;
        });
    };
}

export function getConversationsRequest(search = '', filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, items = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`conversations?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter && sorter.column ? `sortBy=${sorter.column}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                items = items.concat(res.conversations);
                if(!pager && res.conversations && res.conversations.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, items));
                }
                resolve(items);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('conversations'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? pager.current * pager.size : 0).then(conversations => {
            dispatch(setConversations(conversations || []));
            dispatch(setIsFetching('conversations', false));
            return conversations;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function getConversationsByUserRequest(userId) {
    return dispatch => {
        dispatch(setIsFetching('conversations'));
        return callApi(`conversations/${userId}`).then(result => {
            dispatch(setConversations(result.conversations, true));
            dispatch(setIsFetching('conversations', false));
            return result.conversations;
        }).catch(err => {
            return null;
        });
    };
}

export function getAllConversationsRequest() {
    return dispatch => {
        return dispatch(getConversationsRequest());
    };
}

export function getConversationRequest(conversationId) {
    return dispatch => {
        return callApi(`conversation/${conversationId}`).then(result => {
            result.conversation && dispatch(setConversations([result.conversation]));
            return result.conversation;
        }).catch(err => {
            return null;
        });
    };
}

export function getConversationMessagesRequest(conversation, filename = null) {
    return dispatch => {
        if(filename) {
            return getFileContent(`conversations/${conversation._id}/${filename}`).then(response => {
                if(response) {
                    return response.text().then(text => {
                        if(text) {
                            const messages = transformCsvToJson(text);
                            dispatch(setConversationMessages(conversation._id, filename.replace(/\D/g, ''), messages));
                            return messages;
                        }
                        return null;
                    });
                }
                return null;
            }).catch(err => {
                console.error(err);
                return null;
            });
        }
        // If no filename defined => fetch all messages of every blocks
        const selectedMessagesBlocks = conversation.messagesBlocks.filter(messagesBlock => !conversation.dates || !conversation.dates.start || conversation.dates.end || isMessageBlockIncluded(messagesBlock, conversation.dates.start, conversation.dates.end));
        conversation && conversation.dates && console.log(`Conversation time: ${conversation.dates.start} -> ${conversation.dates.end}`);
        return sequence(
            [...selectedMessagesBlocks],
            messagesBlock => {
                return dispatch(getConversationMessagesRequest(conversation, messagesBlock.filename)).then(messages => {
                    return messages;
                });
            },
        ).then(results => {
            let messages = [];
            results.forEach(messagesList => {
                messages = messages.concat(messagesList);
            });
            return messages;
        });
    };
}

export function editConversationRequest(conversation, blockAlertUser = true) {
    return dispatch => {
        return callApi('conversation/edit', 'post', { conversation, blockAlertUser }).then(result => {
            // if(fetchAll) {
            //     return dispatch(getConversationsRequest()).then(conversations => {
            //         return conversations.find(conversation => conversation._id === result.conversation._id);
            //     });
            // }
            // dispatch(setConversations([conversation]));
            if(result && result.conversation) {
                return dispatch(getConversationRequest(result.conversation._id));
            }
            return result && result.conversation;
        }).catch(err => {
            return null;
        });
    };
}

export function uploadConversationMessagesFilesRequest(conversation, messagesBlocks, onProgress = percentile => {}) {
    if(!conversation) {
        return Promise.reject(new Error('NoConversation'));
    }
    const rootPath = `conversations/${conversation._id}/`;
    const percentile = 100 / (messagesBlocks.length);
    return sequence([...messagesBlocks], messagesBlock => {
        return new Promise((resolveBlock, rejectBlock) => {
            const messageFile = blobToFile(new Blob([messagesBlock.content], { type: 'text/csv' }), messagesBlock.filename);
            uploadFile(`${rootPath}${messagesBlock.filename}`, messageFile, 'text/csv').then(result => {
                onProgress(percentile);
                resolveBlock(result);
            }).catch(rejectBlock);
        });
    });
}

export function uploadConversationMediasFilesRequest(conversation, mediasEntries, onProgress = percentile => {}) {
    if(!conversation) {
        return Promise.reject(new Error('NoConversation'));
    }
    const rootPath = `conversations/${conversation._id}/images/`;
    const percentile = 100 / (mediasEntries.length);
    let count = 0;
    return sequence([...mediasEntries], mediaEntry => {
        return new Promise((resolveImage, rejectImage) => {
            const extension = mediaEntry.filename.split('.').pop();
            const imageFile = blobToFile(mediaEntry.content, mediaEntry.filename);
            uploadFile(`${rootPath}${mediaEntry.filename}`, imageFile, `${mediaEntry.type}/${extension}`).then(result => {
                onProgress(percentile);
                setTimeout(() => {
                    count += 1;
                    resolveImage(result);
                }, count % 100 === 0 ? 2000 : 50);
            }).catch(err => {
                notifyError(err, { conversationId: conversation._id, filename: mediaEntry.filename, type: mediaEntry.type });
                resolveImage(null);
            });
        });
    });
}

export function mergeConversationsRequest(conversationIds) {
    return dispatch => {
        return callApi('conversation/merge', 'post', { conversations: conversationIds }).then(result => {
            return result.ok;
        }).catch(err => {
            return null;
        });
    };
}

export function createCartRequest(conversationId) {
    return dispatch => {
        return callApi('conversation/order', 'post', { conversation: { _id: conversationId } }).then(result => {
            dispatch(getConversationsRequest());
            return result;
        }).catch(err => {
            return null;
        });
    };
}

export function validOrderRequest(id, isCart = false) {
    return dispatch => {
        return callApi(`conversation/valid/order?${isCart ? 'cartId' : 'orderId'}=${id}`).then(result => {
            dispatch(getConversationsRequest());
            return result && result.ok;
        }).catch(err => {
            return null;
        });
    };
}

export function getOrdersHistoryLinkRequest(language = getDefaultLanguages()) {
    return callApi(`conversation/history/link/${language}`).then(result => {
        return result && result.url;
    }).catch(err => {
        return null;
    });
}

export function removeConversationRequest(conversationId) {
    return dispatch => {
        return callApi('conversation/remove', 'delete', { conversation: { _id: conversationId } }).then(result => {
            result && result.ok && dispatch(removeConversation(conversationId));
            return result.ok;
        }).catch(err => {
            return null;
        });
    };
}

// Getters
export function getConversations(store, user = null) {
    return store.conversations.data.filter(conversation => !user || getConversationUserId(conversation) === getUserId(user));
}

export function getConversation(store, conversationId) {
    return store.conversations.data.find(conversation => conversation._id === conversationId);
}

export function getConversationMessagesLoaded(store, conversationId) {
    if(store.conversations.messages && store.conversations.messages[conversationId]) {
        return Object.keys(store.conversations.messages[conversationId]).sort((a, b) => b - a);
    }
    return [];
}

export function getConversationMessages(store, conversationId) {
    if(store.conversations.messages && store.conversations.messages[conversationId]) {
        let messages = [];
        getConversationMessagesLoaded(store, conversationId).forEach(index => {
            messages = messages.concat(store.conversations.messages[conversationId][index]);
        });
        return messages;
    }
    return [];
}

export function getBookGeneration(store, conversationId) {
    return store.conversations.bookGeneration[conversationId] || null;
}

export function isTutorialPassed(store, key) {
    // if(process.env.NODE_ENV === 'development') {
    //     return false;
    // }
    return (store.conversations.tutorials || {})[key] && (store.conversations.tutorials || {})[key] >= TUTORIAL_VERSION;
}

// Helper
export function getConversationId(conversation) {
    return conversation && conversation._id ? conversation._id : conversation;
}

export function getConversationUserId(conversation) {
    return conversation && ((conversation.user && conversation.user._id) || conversation.user);
}

export function getConversationCover(conversation, bookIndex) {
    return (bookIndex && ((conversation.books || [])[bookIndex] || {}).cover) || conversation.cover || {};
}
export function getConversationCoverData(conversation, name, bookIndex) {
    return getConversationCover(conversation, bookIndex)[name] || '';
}

export function getConversationResume(conversation, bookIndex) {
    if(bookIndex) { // for bookIndex === 0 => general cover
        return ((conversation.books || [])[bookIndex] || {}).resume || '';
    }
    return conversation.resume || '';
}

export function getConversationParticipantName(conversation, position = 1, type = 'displayName') {
    position = Math.max(1, position);
    if(conversation.participants && conversation.participants[position - 1] && conversation.participants[position - 1][type]) {
        return conversation.participants[position - 1][type];
    }
    return '';
}

export function getMessageParticipant(conversation, name) {
    return conversation.participants.find(participant => participant.name === name);
}

export function getConversationMessagesCount(conversation) {
    return (conversation.messagesBlocks || []).reduce((total, block) => total + block.count, 0);
}

export function getConversationOption(conversation, option) {
    return (conversation && conversation.options && conversation.options[option]) || '';
}

export function getMessageAttachments(messageAttachmentsText) {
    return (messageAttachmentsText || '').toString().split(MESSAGE_ATTACHMENTS_SEPARATOR).filter(attachment => attachment);
}

/*
 * available types: image || audio || video
 */
export function getMessageAttachmentsByType(attachments, types = []) {
    if(types && !Array.isArray(types)) {
        types = [types];
    }
    if(!types || !types.length) {
        return attachments || [];
    }

    const selected = [];

    if(types.includes('image')) {
        selected.push(...(attachments || []).filter(attachment => attachment && ['jpg', 'jpeg', 'bmp', 'png', 'webp'].some(ext => attachment.toLowerCase().includes(`.${ext}`))));
    }

    if(types.includes('video')) {
        selected.push(...(attachments || []).filter(attachment => attachment && ['gif', 'mp4', 'webm'].some(ext => attachment.toLowerCase().includes(`.${ext}`))));
    }

    if(types.includes('audio')) {
        selected.push(...(attachments || []).filter(attachment => attachment && ['mp3', 'opus'].some(ext => attachment.toLowerCase().includes(`.${ext}`))));
    }

    return selected;
}

export function getConversationStatuses() {
    return [CONVERSATION_STATUS_PENDING, CONVERSATION_STATUS_DONE];
}

export function getAvailableTypes() {
    return Object.keys(CONVERSATIONS_EXTRACTROR_TYPES);
    // ['ios', 'android', 'messenger', 'instagram', 'whatsapp'];
}

export function getConversationEditSteps() {
    return CONVERSATION_EDIT_STEPS;
    // ['ios', 'android', 'messenger', 'instagram', 'whatsapp'];
}

export function getAvailableCoverColors(isLegacy = false) {
    return Object.keys(CONVERSATION_COVER_COLORS).filter(key => isLegacy || !CONVERSATION_COVER_COLORS[key].legacy).sort((keyA, keyB) => CONVERSATION_COVER_COLORS[keyA].order - CONVERSATION_COVER_COLORS[keyB].order);
}

export function getCoverColorFromName(name) {
    return CONVERSATION_COVER_COLORS[name];
}

export function getAvailableParticipantColors(isLegacy = false) {
    return Object.keys(CONVERSATION_PARTICIPANT_COLORS).filter(key => isLegacy || !CONVERSATION_PARTICIPANT_COLORS[key].legacy);
}

export function getParticipantColorFromName(name) {
    return CONVERSATION_PARTICIPANT_COLORS[name];
}

export function isMessageBlockIncluded(messagesBlock, start, end) {
    // console.log('isMessageBlockIncluded');
    // console.log(`
    //     Start
    //     base: ${start}
    //     timezoned: ${moment(start).format()}
    //     utc: ${moment.utc(start).format()}
    //     timezone -> utc: ${moment.utc(moment(start).format())}
    //
    //     base: ${messagesBlock.start}
    //     timezoned: ${moment(messagesBlock.start).format()}
    //     utc: ${moment.utc(messagesBlock.start).format()}
    // `);
    // console.log(`
    //     End
    //     ${end}
    //     ${messagesBlock.end}
    //     ${moment(messagesBlock.end).format()}
    // `);
    const result = (moment(messagesBlock.start).isSameOrBefore(start) && moment(messagesBlock.end).isSameOrAfter(start))
    || (moment(messagesBlock.start).isSameOrBefore(end) && moment(messagesBlock.end).isSameOrAfter(end))
    || (moment(start).isSameOrBefore(messagesBlock.start) && moment(end).isSameOrAfter(messagesBlock.end));
    // console.log(result ? 'IN' : 'OUT');
    return result;
}

export function getConversationOrderFilesPath(conversation) {
    return conversation?.order?.files || [conversation.order?.filePath || `conversations/${conversation._id}/commande_${conversation.order?.orderId || ''}.zip`];
}

export function canDowloandFiles(conversation) {
    return (process.env.NODE_ENV === 'development' || conversation.status === CONVERSATION_STATUS_DONE) && getConversationOrderFilesPath(conversation).length > 0 && conversation.dates?.generated && moment.utc().subtract(7, 'days').isBefore(conversation.dates.generated);
}

// Get collector information by provider from CONVERSATIONS_EXTRACTROR_TYPES data
export function getActiveCollector(provider, nameOrCode = '') {
    console.log('getActiveCollector', provider, nameOrCode);
    if(!provider || !CONVERSATIONS_EXTRACTROR_TYPES[provider]) {
        return null;
    }
    const { collectors = [], ...extractorData } = CONVERSATIONS_EXTRACTROR_TYPES[provider];
    const defaultCollector = (collectors?.sort((cA, cB) => cA.sort - cB.sort) || [])[0] || {};
    let activeCollector = {
        collectors,
        ...extractorData,
        ...defaultCollector,
    };
    if(nameOrCode && collectors?.find(collector => [collector.name, collector.code].includes(nameOrCode))) {
        activeCollector = {
            collectors,
            ...extractorData,
            ...collectors.find(collector => [collector.name, collector.code].includes(nameOrCode)),
        };
    }
    console.log('active collector', activeCollector);
    return activeCollector;
}

// Export Actions
export function setConversations(conversations, isSet = false) {
    return {
        type: SET_CONVERSATIONS,
        conversations,
        isSet,
    };
}
export function removeConversation(conversationId) {
    return {
        type: REMOVE_CONVERSATION,
        conversationId,
    };
}

export function setConversationMessages(conversationId, index, messages) {
    return {
        type: SET_CONVERSATION_MESSAGES,
        conversationId,
        index,
        messages,
    };
}

export function setBookGeneration(conversationId, start, end, books, format = 'a5') {
    return {
        type: SET_BOOK_GENERATION,
        conversationId,
        start,
        end,
        books,
        format,
    };
}

export function setTutorial(key, value = TUTORIAL_VERSION) {
    return {
        type: SET_TUTORIAL,
        key,
        value,
    };
}
