import { enabledLanguages, localizationData, defaultLanguage } from '../../../Intl/setup';
import { SWITCH_LANGUAGE } from './IntlActions';

const initLocale = global.navigator && global.navigator.language || defaultLanguage;

const initialState = {
    isInit: false,
    locale: initLocale,
    enabledLanguages,
    messages: (localizationData[initLocale] || {}).messages || {},
};

const IntlReducer = (state = initialState, action) => {
  switch(action.type) {

    case SWITCH_LANGUAGE: {
      const { type, locale, messages } = action; // eslint-disable-line
          return {
              ...state,
              isInit: true,
              locale,
              messages,
          };
  }

    default:
      return state;
  }
};

export default IntlReducer;
