'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { Slide, ToastContainer } from 'react-toastify';

function ToastProvider({ children }) {
    return (
        <div>
            {children}
            <ToastContainer position="top-right" transition={Slide} offset={50} autoClose={10000} limit={5} />
        </div>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ToastProvider;
