import moment from 'moment';

import { readArchive } from '../../../util/archive';
import { sequence } from '../../../util/promises';

import { DATE_FORMAT_OUTPUT, MESSAGES_BLOCK_COUNT, MESSAGE_ATTACHMENTS_SEPARATOR } from '../ConversationActions';

// Read selected file by user
// For Messenger: User must select an archive
export function readFile(file, options, onProgress) {
    if(file.type.includes('json')) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                onProgress(100);
                console.log(reader.result);
                resolve([
                    {
                        path: file.name,
                        content: reader.result,
                    },
                ]);
            };
            reader.readAsText(file);
        });
    }
    return readArchive(file, options, onProgress);
}

export function getFiles(file, filesPaths = [], onProgress = () => {}) {
    return new Promise((resolve, reject) => {
        // console.log('FB getFiles', filesPaths);
        // console.log('local images', filesPaths.filter(imagePath => !/http(s)?:\/\//.test(imagePath)));
        // console.log('distant images', filesPaths.filter(imagePath => /http(s)?:\/\//.test(imagePath)));
        const localImages = filesPaths.filter(imagePath => !/http(s)?:\/\//.test(imagePath));
        readArchive(
            file,
            { includeRegexFiles: [
                // ...localImages.map(imagePath => new RegExp(imagePath, 'gi')),
                // ...localImages.map(imagePath => new RegExp(imagePath.replace('messages/', ''), 'gi')),
                // ...localImages.map(imagePath => new RegExp(imagePath.replace('messages/inbox/', ''), 'gi')),
                ...localImages.map(imagePath => new RegExp(imagePath.split('/').pop(), 'gi')),
            ] },
            onProgress,
        ).then(localFiles => {
            console.log('Local files done', localFiles);

            return readArchive(
                file,
                { includeRegexFiles: filesPaths.filter(imagePath => /http(s)?:\/\//.test(imagePath)).map(imagePath => new RegExp(getMediaFileName(imagePath).split('.').shift(), 'gi')) },
                onProgress,
            ).then(distantFiles => {
                console.log('Distant files done', distantFiles);
                resolve([
                    ...(localFiles || []),
                    ...(distantFiles || []).map(distantImage => {
                        const ext = distantImage.filename.split('.').pop();
                        const filename = getMediaFileName(filesPaths.find(imagePath => {
                            const imagePathParts = distantImage.filename.split('_');
                            imagePathParts.pop();
                            return imagePath.includes(imagePathParts.join('_'));
                        }) || '');
                        return {
                            ...distantImage,
                            filename,
                        };
                    }),
                ]);
            }).catch(reject);

            // const percentile = 100 / filesPaths.length;
            // return sequence(filesPaths, imagePath => {
            //     console.log('Fetch distant image', imagePath);
            //     return new Promise((resolveDistant, rejectDistant) => {
            //         setTimeout(() => {
            //             fetch(imagePath, {
            //                 method: 'GET',
            //             },
            //             {
            //                 'Access-Control-Allow-Origin': 'no-cors',
            //             })
            //             .then(response => {
            //                 response.blob().then(blob => {
            //                     onProgress(percentile);
            //                     resolveDistant({
            //                         filename: getMediaFileName(imagePath),
            //                         content: blob,
            //                         type: response.headers.get('content-type'),
            //                     });
            //                 });
            //             })
            //             .catch(err => {
            //                 onProgress(percentile);
            //                 console.error('getImages', imagePath, err);
            //                 resolveDistant(false);
            //             });
            //         }, 100);
            //     });
            // }).then(distantFiles => {
            //     console.log('Distant images', distantFiles);
            //     resolve([
            //         ...(localFiles || []),
            //         ...(distantFiles || []),
            //     ]);
            // }).catch(reject);
        }).catch(reject);
    });
}

export function canHandleImages(file) {
    return !['json'].some(type => file.type.includes(type));
}

// Retrieve profile name
export function getProfileName(entries) {
    let participants = [];
    entries.forEach(entry => {
        const json = JSON.parse(entry.content) || {};
        (json.participants || []).forEach(participant => {
            let newParticipant = participants.find(existingParticipant => existingParticipant.name === participant.name);
            if(newParticipant) {
                newParticipant = {
                    ...newParticipant,
                    count: newParticipant.count + 1,
                };
            } else {
                newParticipant = {
                    ...participant,
                    count: 1,
                };
            }
            participants = participants.filter(existing => existing.name !== newParticipant.name);
            participants.push(newParticipant);
        });
    });
    return decodeURIComponent(escape(participants && participants.length ? participants.sort((a, b) => b.count - a.count).shift().name : ''));
}

// Retrieve threads names
export function getThreads(entries) {
    let threads = [];

    entries.forEach(entry => {
        const json = JSON.parse(entry.content);

        const name = decodeURIComponent(escape(json.title));
        const id = entry.path;

        let friendThread = threads.find(thread => thread.name === name);
        if(!friendThread) {
            friendThread = {
                name,
                id,
                providerId: json.thread_path.toLowerCase(),
                count: 0,
            };
        }

        friendThread.count += json.messages ? json.messages.length : 0;

        threads = threads.filter(thread => thread.name !== name);

        threads.push(friendThread);
    });

    return threads;
}

// Generate CSV Content
// Separate messages in distinct csv (10 000 messages / csv)
export function getArchiveContent(entries, from, thread, options = { withImages: false, withMedias: false }, onProgress = percentile => {}) {
    console.log(thread, from, options, entries);

    const participants = [];
    let messages = [];
    const messagesBlocks = [];
    let images = [];
    let medias = [];

    entries
    .filter(entry => {
        // console.log(entry.path);
        // console.log(`'messages/'' uncluded? ${`${entry.path}`.toLowerCase().includes('messages/')}`);
        // console.log(`Thread providerId included? ${`${entry.path}`.toLowerCase().includes(thread.providerId.toLowerCase())}`);
        `${entry.path}`.toLowerCase().includes('messages/') && !`${entry.path}`.toLowerCase().includes(thread.providerId.toLowerCase()) && console.error('Entry path does not include providerId', entry.path, thread.providerId);
        return !`${entry.path}`.toLowerCase().includes('messages/') || `${entry.path}`.toLowerCase().includes(thread.providerId.toLowerCase());
    })
    .forEach(entry => {
        onProgress(1); // Init progress

        // Need to escape unicode sequence before json parse
        const conversationData = JSON.parse(decodeURIComponent(escape(entry.content)));
        messages = messages.concat(conversationData.messages);
    });

    messages = messages
        .sort((a, b) => a.timestamp_ms - b.timestamp_ms)
        .map(message => {
            return {
                ...message,
                ...getMessageContent(message, from, thread.name, options),
            };
        })
        // .filter(({ messageMedias }) => {
        //     if(process.env.NODE_ENV === 'development') {
        //         return messageMedias.length && messageMedias.find(media => media.includes('.mp4'));
        //     }
        //     return true;
        // })
        .filter(messageData => {
            // !messageData.messageCsv && console.error('Message not valid', messageData);
            // messageData.messageImages.length && console.log(messageData.messageImages, entries, entries.some(entry => messageData.messageImages.some(imagePath => imagePath && `${entry.path}`.toLowerCase().includes(imagePath.toLowerCase()))));
            // @TODO: Check if image file is in entries (image entries)
            return messageData.messageCsv;
        });

    const iteration = Math.floor(messages.length / MESSAGES_BLOCK_COUNT);

    const percentile = 100 / (iteration + 1);

    console.log(`${messages.length} messages to check`);

    for(let i = 0; i <= iteration; i += 1) {
        console.log(`Messages block process: ${i + 1}/${iteration + 1} (de ${i * MESSAGES_BLOCK_COUNT} à ${i * MESSAGES_BLOCK_COUNT + MESSAGES_BLOCK_COUNT})`);
        const messagesBlock = messages.slice(i * MESSAGES_BLOCK_COUNT, i * MESSAGES_BLOCK_COUNT + MESSAGES_BLOCK_COUNT);

        onProgress(percentile);

        let csv = '';
        messagesBlock.forEach((message, j) => { // eslint-disable-line
            const { messageParticipant, messageCsv, messageImages, messageMedias } = message;

            if(!participants.includes(messageParticipant)) {
                participants.push(messageParticipant);
            }
            csv += messageCsv;

            csv += '\n';
            images = images.concat(messageImages);
            medias = medias.concat(messageMedias);
        });

        if(messagesBlock[0]) {
            messagesBlocks.push({
                sort: i,
                filename: `messages_${i + 1}.csv`,
                content: csv,
                messages: messagesBlock,
                start: new Date(messagesBlock[0].timestamp_ms),
                end: new Date((messagesBlock[messagesBlock.length - 1] || messagesBlock[0]).timestamp_ms),
            });
        } else {
            console.error('Missing messages in block', messagesBlock, participants);
        }
    }

    return {
        participants,
        messagesBlocks,
        images,
        medias,
    };
}

function getMessageContent(message, from, to, options = { withImages: false, withMedias: false }) {
    let messageCsv = '';
    const medias = [];

    const { medias: messageImages, content: imagesExtraContent } = getMessageMedias(message, 'image');
    const { medias: messageMedias, content: mediasExtraContent } = getMessageMedias(message, ['audio', 'video']);
    message.content = (message.content || '') + (imagesExtraContent || '') + (mediasExtraContent || '');

    if(options.withImages) {
        medias.push(...messageImages);
    }
    if(options.withMedias) {
        medias.push(...messageMedias);
    }

    const senderName = decodeURIComponent(escape(message.sender_name));

    const data = {
        date: moment(message.timestamp_ms, 'x').format(DATE_FORMAT_OUTPUT).split('+').shift(),
        sender: senderName,
        to: senderName === from ? to : from,
        content: message.content ? encodeURIComponent(decodeURIComponent(escape(message.content))) : '', // escape and decode unicode before encodeURIComponenent
        isSender: senderName === from ? 'true' : 'false',
        medias: (medias && medias.length ? medias.map(media => getMediaFileName(media)).join(MESSAGE_ATTACHMENTS_SEPARATOR) : ''),
    };

    messageCsv = message.content || medias.length ? `${Object.keys(data).map(key => data[key]).join(';')};` : '';

    return { messageParticipant: senderName, messageCsv, messageImages, messageMedias };
}

// Retrieve profile name
function getMessageMedias(message, types = null) {
    let content = '';
    if(types && !Array.isArray(types)) {
        types = [types];
    }
    if(!types || !types.length) {
        types = null;
    }

    const medias = [];
    if(message.photos && message.photos.length && (!types || types.includes('image'))) {
        const photos = message.photos.filter(photoData => photoData.uri /* && !photoData.uri.includes('.gif') */).map(photoData => photoData.uri.toLowerCase());
        medias.push(...photos);
    }

    if(message.gifs && message.gifs.length && (!types || types.includes('video'))) {
        const gifs = message.gifs.filter(gifData => gifData.uri).map(gifData => gifData.uri.toLowerCase());
        medias.push(...gifs);
    }

    if(message.videos && message.videos.length) {
        const videoUris = !types || types.includes('video')
                ? message.videos.filter(videoData => videoData.uri).map(videoData => videoData.uri.toLowerCase())
                : [];

        const videoThumbnails = !types || types.includes('image')
                ? message.videos.filter(videoData => videoData.thumbnail && videoData.thumbnail.uri).map(videoData => videoData.thumbnail.uri.toLowerCase())
                : [];
        videoUris.length && medias.push(...videoUris);
        videoThumbnails.length && medias.push(...videoThumbnails);
    }

    if(message.audio_files && message.audio_files.length && (!types || types.includes('audio'))) {
        const audios = message.audio_files.filter(audioData => audioData.uri).map(audioData => audioData.uri.toLowerCase());
        medias.push(...audios);
    }

    if(message.sticker && (!types || types.includes('image'))) {
        const sticker = message.sticker.uri.toLowerCase();
        if([
            '39178562_1505197616293642_5411344281094848512_n_369239263222822.png',
            '851587_369239346556147_162929011_n_369239343222814.png',
            '851582_369239386556143_1497813874_n_369239383222810.png',
            '851577_246547505491999_862435009_n_227878347358915.png',
        ].some(thumb => sticker.includes(thumb))) {
            content = encodeURIComponent('👍');
        } else {
            medias.push(sticker);
        }
    }

    // console.log('message medias', medias);
    return { medias, content };
}

function getMediaFileName(path) {
    return path.split('?').shift().split('/').pop();
}
