import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ListGroupItem, ButtonGroup, Button, Collapse, Input } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import moment from 'moment';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import config from '../../../../config';

import { dateFormat } from '../../../../util/date';
import { getFileUrl } from '../../../../util/file';

import { editConversationRequest, removeConversationRequest, getConversationCoverData, getConversationEditSteps, getConversationOrderFilesPath, getConversationOption, CONVERSATION_STATUS_PENDING, CONVERSATION_STATUS_DONE, canDowloandFiles } from '../../ConversationActions';
import { displayErrors, confirmAction } from '../../../Error/ErrorActions';
import withRouter from '../../../../components/Router/WithRouter';
import IntlCountFormat from '../../../Intl/components/view/IntlCountFormat';
import { sequence } from '../../../../util/promises';

class ConversationListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isDeleting: false,
            isDownloaded: false,
        };
    }

    getMessagesCount() {
        const { conversation } = this.props;
        if(conversation.books && conversation.books.length) {
            return conversation.books.reduce((total, book) => total + book.messagesCount, 0);
        } if(conversation.messagesBlocks && conversation.messagesBlocks.length) {
            return conversation.messagesBlocks.reduce((total, messagesBlock) => total + messagesBlock.count, 0);
        }
        return 0;
    }

    toggleOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    // handleDownload = () => {
    //     // save for client
    //     sequence([...getConversationOrderFilesPath(this.props.conversation)], path => getFileUrl(path).then(url => ({ url, path }))).then(datas => {
    //         datas.forEach(({ url, path }) => {
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.target = '_blank';
    //             a.download = path.split('/').pop();
    //             // console.log('Download', path, url, a.download);
    //             a.click();
    //         });

    //         (
    //             (this.props.conversation.dates || {}).downloaded
    //             ? Promise.resolve(this.props.conversation)
    //             : this.props.dispatch(editConversationRequest({
    //                 ...this.props.conversation,
    //                 dates: {
    //                     ...(this.props.conversation.dates || {}),
    //                     downloaded: moment.utc().format(),
    //                 },
    //             }))
    //         ).then(conversation => {
    //             this.setState({
    //                 isDownloaded: true,
    //             }, () => {
    //                 setTimeout(() => {
    //                     this.setState({
    //                         isDownloaded: false,
    //                     });
    //                 }, 5 * 60 * 1000);
    //             });
    //         });
    //     });
    // };

    handleDelete = () => {
        confirmAction(
            '',
            this.props.intl.formatMessage({
                id: 'conversationRemoveConfirmText',
                defaultMessage: 'By removing your conversation, your medias will be removed and can not be retrived!',
            }),
            () => {
                this.setState({
                    isDeleting: true,
                });
                this.props.dispatch(removeConversationRequest(this.props.conversation._id)).then(result => {
                    this.setState({
                        isDeleting: false,
                    });
                    if(result) {
                        displayErrors('success', this.props.intl.formatMessage({
                            id: 'conversationRemoveSuccess',
                            defaultMessage: 'Conversation removed!',
                        }));
                    } else {
                        displayErrors('error', this.props.intl.formatMessage({
                            id: 'alertGenericError',
                            defaultMessage: 'An error occured! Please try later',
                        }));
                    }
                });
            },
        );
    };

    renderDates(start = null, end = null, retry = 0) {
        if(retry > 100) {
            return null;
        }
        const { conversation } = this.props;
        const format = 'LL';

        if(start) {
            return (
                <Fragment>
                    {dateFormat(start, format)}<br />
                    {end && dateFormat(start, format) !== dateFormat(end, format) && <span>{dateFormat(end, format)}</span>}
                </Fragment>
            );
        }
        if(conversation.dates && conversation.dates.start) {
            return this.renderDates(conversation.dates.start, conversation.dates.end, retry + 1);
        }

        if(conversation.messagesBlocks && conversation.messagesBlocks.length) {
            return this.renderDates(conversation.messagesBlocks[0].start, conversation.messagesBlocks[conversation.messagesBlocks.length - 1].end, retry + 1);
        }

        return conversation.dateStatus && dateFormat(conversation.dateStatus[CONVERSATION_STATUS_PENDING], format);
    }

    render() {
        const { conversation, onChangeSelection } = this.props;
        return (
            <ListGroupItem key={conversation._id}>
                <Row className="g-0">
                    <Col xs="3" md="2" lg="1">
                        {(getConversationOption(conversation, 'types') || getConversationOption(conversation, 'providers')).map(provider => <img key={provider} src={`${config.staticUrl}assets/images/${provider}.png`} role="presentation" className="w-75" />)}
                        {onChangeSelection && (
                            <Toggle
                                name="selection"
                                onChange={event => onChangeSelection(conversation, event.target.checked)}
                            />
                        )}
                    </Col>
                    <Col>
                        <Row>
                            <Col xs="12" md="8" className="text-start ps-5">
                                <strong className="text-secondary">{getConversationCoverData(conversation, 'from')} {getConversationCoverData(conversation, 'to')}</strong><br />
                            </Col>
                            <Col className="text-left text-md-right">
                                <small>{conversation.participants.slice(0, 3).map(participant => participant.displayName).join(', ')}{conversation.participants.length > 3 && '...'}</small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="mt-2 text-xs">
                    <Row>
                        <Col xs="6">
                            <strong><FormattedMessage id="conversationMessagesCount" defaultMessage="{count} message(s)" values={{ count: <IntlCountFormat value={this.getMessagesCount()} /> }} /></strong><br />
                            {conversation.imagesCount && <FormattedMessage id="conversationMessagesImagesCount" defaultMessage="{count} image(s)" values={{ count: <IntlCountFormat value={conversation.imagesCount} /> }} />}<br />
                            {conversation.mediasCount && <FormattedMessage id="conversationMessagesMediasCount" defaultMessage="{count} media(s)" values={{ count: <IntlCountFormat value={conversation.mediasCount} /> }} />}
                        </Col>
                        <Col className="text-right">
                            <small>{this.renderDates()}</small>
                        </Col>
                    </Row>
                </div>
                <div className="mt-2 text-center">
                    <ButtonGroup block="true" size="sm">
                        <Button color="danger" outline onClick={this.handleDelete} disabled={this.state.isDeleting}>{this.state.isDeleting ? <FontAwesome name="spinner" spin /> : <FontAwesome name="times-circle" />} <FormattedMessage id="conversationRemoveButton" defaultMessage="Remove" /></Button>
                        {canDowloandFiles(this.props.conversation) && (
                            <Button color="warning" tag={Link} to={`/conversation/${conversation._id}/download`}><FontAwesome name="download" /> <FormattedMessage id="conversationDownloadPdfButton" defaultMessage="Download E-Book" />{this.state.isDownloaded && <small>(<FormattedMessage id="conversationDownloadPdfWaitingMessage" defaultMessage="Wait 5minutes to download again" />)</small>}</Button>
                        )}
                        <Button color="warning" outline tag={Link} to={`/conversation/${conversation._id}/cover`}>
                            {
                                conversation.status === CONVERSATION_STATUS_DONE
                                ? <Fragment><FontAwesome name="undo" /> <FormattedMessage id="conversationActionRestart" defaultMessage="Restart" /></Fragment>
                                : <Fragment><FontAwesome name="play-circle" /> <FormattedMessage id="conversationActionContinue" defaultMessage="Continue" /></Fragment>
                            }
                        </Button>
                        {process.env.NODE_ENV !== 'production' && <Button color="primary" outline onClick={this.toggleOpen}><FontAwesome name={this.state.isOpen ? 'minus' : 'plus'} /></Button>}
                    </ButtonGroup>
                    <Collapse isOpen={this.state.isOpen}>
                        <Input type="select" value="" onChange={event => this.props.navigate(`/conversation/${conversation._id}/${event.target.value}`)}>
                            {getConversationEditSteps().filter(step => step !== 'success').map(step => <option key={step} value={step}>{step}</option>)}
                        </Input>
                    </Collapse>
                </div>
            </ListGroupItem>
        );
    }
}

function mapStateToProps(store, props) {
    return {
    };
}

ConversationListItem.defaultProps = {
    onChangeSelection: null,
};

ConversationListItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    onChangeSelection: PropTypes.func,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ConversationListItem)));
