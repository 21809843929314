import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';

function AppLogo(props) {
    return (
        <img src={`${config.staticUrl}assets/images/logo-monlivresms-2.png`} alt={config.application.name} {...props} />
    );
}


AppLogo.propTypes = {
};

export default AppLogo;
