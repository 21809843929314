import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, ListGroup, ListGroupItem, Alert } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import config from '../../../../config';

import { getConversationsByUserRequest, getConversations } from '../../../Conversation/ConversationActions';
import { getLoggedUser } from '../../UserActions';
import { isFetching } from '../../../App/AppActions';

import PageTitle from '../../../../components/Content/PageTitle';
import ConversationList from '../../../Conversation/components/list/ConversationList';
import ConversationTypeSelector from '../../../Conversation/components/ConversationTypeSelector';

class CustomerDashboard extends Component {

    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user !== this.props.user) {
            this.handleFetch();
        }
    }

    handleFetch = () => {
        this.props.user && this.props.dispatch(getConversationsByUserRequest(this.props.user._id));
    }

    render() {
        const { user, conversations } = this.props;

        if(user) {
            return (
                <Row>
                    <Col md="12" xl="4" className="mb-3">
                        <PageTitle>
                            <FormattedMessage id="conversationsMy" defaultMessage="My conversations" />
                        </PageTitle>
                        {
                            conversations && conversations.length
                            ? <ConversationList />
                            : (
                                <Alert color="light" className="text-center">
                                    <FontAwesome name="infor-circle" /> <FormattedMessage id="conversationNoData" defaultMessage="You didn't create a conversation yet!" /> <br /><br />
                                    <FormattedMessage id="conversationCreateInfoText" defaultMessage="Create a new book conversation by clicking on the icon here" />
                                    <br /><br />
                                    <FontAwesome name="arrow-right" size="3x" className="d-none d-xl-inline" /><FontAwesome name="arrow-down" size="3x" className="d-inline d-xl-none" />
                                </Alert>
                            )
                        }
                    </Col>
                    <Col>
                        <ConversationTypeSelector />
                    </Col>
                </Row>
            );
        }
        return null;
    }
}

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        user,
        conversations: getConversations(store, user),
        isFetching: {
        },
    };
}

CustomerDashboard.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    conversations: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.object,
};

export default connect(mapStateToProps)(injectIntl(CustomerDashboard));
