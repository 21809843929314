import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import PageTitle from '../../../components/Content/PageTitle';
import CustomerDashboard from './dashboard/CustomerDashboard';
import AdminDashboard from './dashboard/AdminDashboard';

class Dashboard extends Component {

    renderUserDashboardList() {
        switch(this.props.user.role) {
            // case 'admin':
            //     return <AdminDashboard />;

            case 'customer':
            default:
                return <CustomerDashboard />;
        }
    }

    render() {
        return (
            <Container fluid>
                {this.props.user && this.renderUserDashboardList()}
            </Container>
        );
    }
}

// <Test />

function mapStateToProps(store) {
    return {
        user: store.users.user,
    };
}

Dashboard.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps)(Dashboard);
