const envVars = Object.assign(process.env, ((typeof window !== 'undefined' && window.env_vars) || {}));

const port = envVars.PORT || 8000;
const url = envVars.BASE_URL || `http://localhost:${port}/`;

const config = {
    version: envVars.APP_VERSION || '',
    url,
    api: envVars.API_URL || `${url}api`,
    staticUrl: envVars.STATIC_URL || url, // 'https://s3.eu-central-1.amazonaws.com/monlivresms-public/assets/',
    ws: envVars.WEBSOCKET_URL || '',
    port,
    application: {
        name: 'MonLivreSMS',
        email: 'bonjour@monlivresms.com',
        options: {
        },
        book: {
            filenamePrefix: 'MLS',
            // BELOW is NOT USED ANYMORE !!
            police: {
                textFont: '/fonts/AvenirLTStd-Light.ttf',
                boldFont: '/fonts/AvenirLTStd-Book.ttf',
            },
            page: {
                // arbitrary bimbo values in pixels unit. (In css print, a pixel is 1/96 inches)
                // Needed for styling the size of document pages
                // BPO: Although not comprehensible, I do not touch them because they are fitting
                // with document templates and required output.
                // width: 382.5,
                // height: 530,
                width: 350,
                height: 520,
                // margin: 50,
            },
            // all values in mm
            cover: {
                fond: 11.1, // indications for cut lines
                coupe: 5.7, // indications for cut lines
                tranche: 0.06, // page thickness
                hauteur: 190, // page height
                largeur: 135, // page width
            },
            pageLimit: 400, // Should be a multiple of 40, as books are segmented by 40 pages for performances reasons

            footerSignature: 'Made in 🇫🇷  with ❤️', // USED
        },
    },
    website: {
        url: 'https://monlivresms.com',
    },
    prestashop: {
        urls: {
            shop: envVars.PRESTASHOP_URL_SHOP || 'https://shop.monlivresms.com',
        },
    },
    aws: {
        s3: {
            region: 'eu-west-3',
            bucket: envVars.S3_BUCKET || 'mlsms-dev',
        },
    },
    bugsnag: {
        key: envVars.BUGSNAG_API_KEY || '',
    },
    google: {
        analytics: {
            trackingCode: envVars.GOOGLE_ANALYTICS_TRACKING_CODE || '',
        },
    },
};

false && process.env.NODE_ENV === 'development' && console.log('Client config', config);
export default config;
// module.exports = config;
