/* eslint-disable global-require */
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router';

import authorization from './components/Auth/AuthorizationComponent';
// import pageAnimator from './components/Content/PageAnimator';
import Loader from './components/Loader/Loader';
import App from './modules/App/App';

// Role Filter
const admin = authorization(['admin']);

const customer = authorization(['customer']);
const customerUp = authorization(['customer', 'admin']);

const baseRoute = '';

function getPath(path = '', isConsole = false) {
    return `${baseRoute}/${isConsole ? 'console' : ''}${path && isConsole ? '/' : ''}${path || ''}`;
}

const setAdminAuth = adminRoute => ({ auth: ['admin'], ...adminRoute, children: (adminRoute.children || []).map(setAdminAuth) });

const UserListPage = lazy(() => import('./modules/User/pages/UserListPage/UserListPage'));
const UserEditPage = lazy(() => import('./modules/User/pages/UserEditPage/UserEditPage'));
const ConversationAdminListPage = lazy(() => import('./modules/Conversation/pages/admin/list/ConversationListPage'));
const ConfigPage = lazy(() => import('./modules/Config/pages/ConfigPage/ConfigPage'));

const HomePage = lazy(() => import('./modules/App/pages/view/HomePage'));
const CmsPage = lazy(() => import('./modules/Cms/pages/CmsPage/CmsPage'));
const ContactPage = lazy(() => import('./modules/Contact/pages/ContactPage/ContactPage'));
const UserLoginPage = lazy(() => import('./modules/User/pages/UserLoginPage/UserLoginPage'));
const UserRegisterPage = lazy(() => import('./modules/User/pages/UserRegisterPage/UserRegisterPage'));
const UserProfilePage = lazy(() => import('./modules/User/pages/UserProfilePage/UserProfilePage'));
const ConversationListPage = lazy(() => import('./modules/Conversation/pages/list/ConversationListPage'));
const ConversationMediaViewerPage = lazy(() => import('./modules/Conversation/pages/view/ConversationMediaViewerPage'));
const ConversationEditPage = lazy(() => import('./modules/Conversation/pages/edit/ConversationEditPage'));

const ConversationCollectorTest = lazy(() => import('./modules/Conversation/pages/view/ConversationCollectorTest'));

export const routesData = []
    .concat([
        {
            path: getPath('users', true),
            component: UserListPage,
        },
        {
            path: getPath('user/:_id', true),
            component: UserEditPage,
        },
        {
            path: getPath('conversations', true),
            component: ConversationAdminListPage,
        },
        {
            path: getPath('config', true),
            component: ConfigPage,
        },
        {
            path: getPath('test', true),
            component: ConversationCollectorTest,
        },
    ]).map(setAdminAuth)
    .concat([
        {
            path: getPath('user/login'),
            component: UserLoginPage,
        },
        {
            path: getPath('user/resetpassword'),
            component: UserLoginPage,
        },
        {
            path: getPath('user/register'),
            component: UserRegisterPage,
            children: [
                {
                    path: ':success',
                    component: UserRegisterPage,
                },
            ],
        },
        {
            path: getPath('user/profile'),
            component: UserProfilePage,
        },
        {
            path: getPath('page/:_pageId'),
            component: CmsPage,
        },
        {
            path: getPath('contact'),
            component: ContactPage,
        },
        {
            path: getPath('conversations'),
            component: ConversationListPage,
            auth: ['admin', 'customer'],
        },
        {
            path: getPath('conversation/:_id/viewer/:data'),
            component: ConversationMediaViewerPage,
        },
        {
            path: getPath('conversation/:_id'),
            component: ConversationEditPage,
            auth: ['admin', 'customer'],
            children: [
                {
                    path: ':step',
                    component: ConversationEditPage,
                },
            ],
        },
        {
            path: getPath(''),
            component: HomePage,
        },
    ].map(route => ({
        ...route,
        component: route.auth ? authorization(route.auth, <UserLoginPage />)(route.component) : route.component,
    })));

const renderRoutes = (routes, prefix = '') => {
    return routes.map((route, index) => {
        const path = `${prefix ? `${prefix}/` : ''}${route.path}`;
        const key = route.path || index;

        if(route.redirect) {
            return <Route key={key} path={path} element={<Navigate replace to={route.redirect} />} />;
        }
        // if(route.pagePath) {
        //     return (
        //         <Route key={index} {...route} render={props => <AsyncComponent path={route.pagePath} {...props} />} />
        //     );
        // }
        // prefix && console.log(prefix, route.path, path);
        return (
            <Route key={key} path={path} index={!route.path} element={<Suspense fallback={<Loader />}><route.component /></Suspense>}>
                {route.children && route.children.length > 0 && renderRoutes(route.children, path)}
            </Route>
        );
    });
};

// react-router setup with code-splitting
function AppRoutes({ ssr = false }) {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                {renderRoutes(routesData)}
            </Route>
        </Routes>
    );
}

AppRoutes.propTypes = {
    ssr: PropTypes.bool,
 };

export default AppRoutes;
