import React from 'react';
import PropTypes from 'prop-types';

const splitEveryN = (str, n, arr = [], isReverse = false) => {
    if(str.length === 0) {
        return isReverse ? arr.reverse() : arr;
    }

    const part = isReverse ? str.slice(-n) : str.slice(0, n);
    arr.push(part);
    return splitEveryN(isReverse ? str.slice(0, Math.max(0, str.length - n)) : str.slice(n), n, arr, isReverse);
};

function CountFormat({ value, decimalSeparator, thousandsSeparator }) {
    const integer = parseInt(value, 10);
    const decimals = `${value - integer}`.replace('0.', '');
    const thousandsParts = splitEveryN(`${value || ''}`, 3, [], true);
    return (
        <span>{thousandsParts.join(thousandsSeparator)}{decimals > 0 && decimalSeparator}{decimals > 0 && decimals}</span>
    );
}

CountFormat.defaultProps = {
    decimalSeparator: '.',
    thousandsSeparator: ' ',
};

CountFormat.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    decimalSeparator: PropTypes.string,
    thousandsSeparator: PropTypes.string,
};

export default CountFormat;
