/**
 * Root Reducer
 */
import { combineReducers } from 'redux';

// Import Reducers
import app from './modules/App/AppReducer';
import intl from './modules/Intl/IntlReducer';
import error from './modules/Error/ErrorReducer';
import config from './modules/Config/ConfigReducer';
import cms from './modules/Cms/CmsReducer';
import currency from './modules/Currency/CurrencyReducer';


import users from './modules/User/UserReducer';
import processes from './modules/Process/ProcessReducer';
import conversations from './modules/Conversation/ConversationReducer';

// Combine all reducers into one root reducer
export default combineReducers({
    app,
    cms,
    config,
    conversations,
    currency,
    error,
    intl,
    processes,
    users,
});
