import moment from 'moment';

import config from '../../config';

import { sequence } from '../../util/promises';
import { dateFormat } from '../../util/date';
import { nl2br, encodeText } from '../../util/text';
import { getHashKeys } from '../../util/security';

import { getConversationMessagesRequest, isMessageBlockIncluded, getMessageAttachments, MESSAGE_ATTACHMENT_VIEWER_DATA_SEPARATOR } from './ConversationActions';
import { notifyError } from '../../components/Error/ErrorBoundary';

export const BOOK_FORMATS_AVAILABLE = ['a5'];
export const BOOK_TYPES_AVAILABLE = ['book', 'pdf'];

const FONT_BASE_A5 = 10;
const FONT_BASE_A4 = 9;

const BOOK_PAGES_COUNT = false && process.env.NODE_ENV !== 'production' ? 10 : 400;
export const BOOK_PAGES_EXTRA_COUNT = 3; // Extra pages added to final pdf (cover, resume & last)

// Match CSS line-height + margin(top&bottom) + padding(top&bottom)
const BOOK_LINE_DATE_TITLE_SIZE = 2.6; // 1.2 + 1 + 0.4;
const BOOK_LINE_MESSAGE_NAME_SIZE = 0.9; // 0.6 + 0.3;
const BOOK_MESSAGE_WRAPPER_SIZE = 0.2;
const BOOK_MESSAGE_BUBBLE_SIZE = 0.4;
const BOOK_LINE_MESSAGE_SIZE = 1; // 1.1

const BOOK_LINE_MESSAGE_CHARS_COUNT = 50;
const BOOK_LINE_MESSAGE_ATTACHMENT_SIZE = 14; // (+ 0.3 (margin) + 0(border) + 0(qrcode)); // Lines count for 1 attachment
const BOOK_LINE_MESSAGE_ATTACHMENTS_COUNT = 3; // Count of attachments per line

export const BOOK_TITLE_MAX_LENGHT = 16;
export const BOOK_SUBTITLE_MAX_LENGHT = 28;
export const BOOK_RESUME_MAX_LENGHT = 1000;

export const BOOK_CONSTANTS = {
    a5: {
        pagesCount: BOOK_PAGES_COUNT,
        pagesPerSheet: 1,
        pageSize: 469,
        dateTitleSize: FONT_BASE_A5 * BOOK_LINE_DATE_TITLE_SIZE,
        messageNameSize: FONT_BASE_A5 * BOOK_LINE_MESSAGE_NAME_SIZE,
        messageWrapperSize: FONT_BASE_A5 * BOOK_MESSAGE_WRAPPER_SIZE,
        messageBubbleSize: FONT_BASE_A5 * BOOK_MESSAGE_BUBBLE_SIZE,
        messageLineSize: FONT_BASE_A5 * BOOK_LINE_MESSAGE_SIZE,
        messageCharsCount: BOOK_LINE_MESSAGE_CHARS_COUNT,
        attachmentSize: FONT_BASE_A5 * BOOK_LINE_MESSAGE_ATTACHMENT_SIZE,
        attachmentCountPerLine: BOOK_LINE_MESSAGE_ATTACHMENTS_COUNT,
    },
    a4: {
        pagesCount: BOOK_PAGES_COUNT * 2,
        pagesPerSheet: 2,
        pageSize: 700,
        dateTitleSize: FONT_BASE_A4 * BOOK_LINE_DATE_TITLE_SIZE,
        messageNameSize: FONT_BASE_A4 * BOOK_LINE_MESSAGE_NAME_SIZE,
        messageWrapperSize: 0, // FONT_BASE_A4 * BOOK_MESSAGE_WRAPPER_SIZE,
        messageBubbleSize: FONT_BASE_A4 * BOOK_MESSAGE_BUBBLE_SIZE,
        messageLineSize: FONT_BASE_A4 * BOOK_LINE_MESSAGE_SIZE,
        messageCharsCount: BOOK_LINE_MESSAGE_CHARS_COUNT,
        attachmentSize: FONT_BASE_A4 * BOOK_LINE_MESSAGE_ATTACHMENT_SIZE,
        attachmentCountPerLine: BOOK_LINE_MESSAGE_ATTACHMENTS_COUNT,
    },
};

export const COVER_IMAGE_SIZE = 227; // 908;
export const COVER_IMAGE_SIZE_FRONT = 227;

export const CONVERSATION_COVER_COLORS = {
    'outremer': { color: '#213E99', background: '#FFF200', back: { circle: { color: '#E6027E' } }, front: { circle: { color: '#FFED00', filename: 'jaune' } }, legacy: true },
    'jaune': { color: '#FFF200', text: '#333', background: '#EC348C', back: { circle: { color: '#009FE3' } }, front: { circle: { color: '#E6027E', filename: 'magenta' } }, legacy: true },
    'magenta': { color: '#EC348C', background: '#FFF200', back: { circle: { color: '#009FE3' } }, front: { circle: { color: '#FFED00', filename: 'jaune' } }, legacy: true },
    'ciel': { color: '#009fe3', background: '#FFF200', back: { circle: { color: '#E6027E' } }, front: { circle: { color: '#FFED00', filename: 'jaune' } }, legacy: true },
    'green': { color: '#6fbc85', background: '#FFF200', back: { circle: { color: '#009FE3' } }, front: { circle: { color: '#FFED00', filename: 'jaune' } }, legacy: true },
    'white': { color: '#fff', text: '#000', background: '#000000', back: { circle: { color: '#000' } }, front: { circle: { color: '#000', filename: 'black' } }, legacy: true },
    'black': { order: 10, color: '#000', text: '#fff', background: '#ffffff', back: { circle: { color: '#fff' } }, front: { circle: { color: '#fff', filename: 'white' } }, logoSuffix: '-white', legacy: true },
    'blue-pastel': { order: 1, color: '#d3e3eb', background: '#ffffff' },
    'green-pastel': { order: 2, color: '#7C9792', background: '#ffffff' },
    'pink-pastel': { order: 3, color: '#fadfe8', background: '#ffffff' },
    'orange-pastel': { order: 4, color: '#ff9a5c', background: '#ffffff' },
    'red-pastel': { order: 5, color: '#bd454a', background: '#ffffff' },
    'buttercream': { order: 10, color: '#EEE0CD', background: '#ffffff' },
};

export const CONVERSATION_PARTICIPANT_COLORS = {
    'grey': { color: '#E5E5EA', text: '#333' },
    'blue': { color: '#0B93F6' },
    'green': { color: '#51e27f', text: '#fff' },
    'pink': { color: '#f567f3', legacy: true },
    'blue-ice': { color: '#51bfe2', legacy: true },
    'brown': { color: '#9F1600', text: '#ffffff', legacy: true },
    'yellow': { color: '#f1f533', text: '#333', legacy: true },
    'black': { color: '#333', text: '#ffffff' },
    'blue-pastel': { color: '#d3e3eb', text: '#333' },
    'green-pastel': { color: '#7C9792', text: '#fff' },
    'pink-pastel': { color: '#fadfe8', text: '#333' },
    'orange-pastel': { color: '#ff9a5c', text: '#fff' },
    'red-pastel': { color: '#bd454a', text: '#fff' },
};

const DISPLAY_LOG = false && process.env.NODE_ENV !== 'production';

export function generateBooks(conversation, options = { format: 'a5', nbPagesByBook: null, divideEqually: false, isEstimation: false }, onProgress = (percentile, slice) => {}) {
    return dispatch => {
        DISPLAY_LOG && console.log('generateBooks', conversation);
        const bookFormat = BOOK_CONSTANTS[(options || {}).format || 'a5'];
        let nbPagesByBook = (options || {}).nbPagesByBook || bookFormat.pagesCount;
        const divideByPages = pages => {
            // console.log('divideByPages', pages);
            const pagesEdit = [...pages];
            const books = [];
            const booksIteration = Math.floor(pages.length / nbPagesByBook);
            if((options || {}).divideEqually) {
                // console.log('Divide equally', `${pages.length} / ${(booksIteration + 1)} = ${pages.length / (booksIteration + 1)} => ${Math.ceil(pages.length / (booksIteration + 1))}`);
                nbPagesByBook = Math.ceil(pages.length / (booksIteration + 1));
            }
            // console.log('nbPagesByBook', nbPagesByBook);
            for(let i = 0; i <= booksIteration; i += 1) {
                books.push({
                    pages: pagesEdit.splice(0, nbPagesByBook),
                });
            }
            // console.log(`Génération de ${books.length} livre(s) (${messages.length} messages et ${books.reduce((total, book) => total + book.pages.reduce((subTotal, page) => subTotal + page.count, 0), 0)} lignes) en ${(new Date().getTime() - timestampStartProcess) / 1000} secondes`);
            // console.log(books);
            return { books, pages };
        };

        return (
            (conversation.messages || []).length
                ? Promise.resolve(conversation.messages)
                : dispatch(getConversationMessagesRequest(conversation))
            ).then(messages => {
                onProgress(100, 20);

                let { start, end } = conversation.dates || {};
                if(false && process.env.NODE_ENV === 'development') {
                    start = '2021-09-08T00:00:00.000Z';
                    end = '2021-09-10T00:00:00.000Z';
                }

                console.log(`Conversation dates: From ${start} -> To ${end}`);
                messages = messages.filter((message, index) => {
                    // message && index < 10 && console.log(message.date, start, start && `${moment(message.date).isSameOrAfter(start)} !== ${moment.utc(message.date).isSameOrAfter(start)}`);
                    return message && message.date && (!start || moment.utc(message.date).isSameOrAfter(start)) && (!end || moment.utc(message.date).isSameOrBefore(end));
                });

                if(false && process.env.NODE_ENV === 'development') {
                    messages = messages.slice(0, 100);
                }

                console.log(`${messages.length} messages to process from ${start} to ${end}`);
                onProgress(100, 20);

                // if(false && (options || {}).isEstimation) {
                //     console.log('ESTIMATED CALCULATION');
                //     console.log('TRY::: Book Calculation from lines count', messages.reduce((lines, message) => lines + ((message[(options || {}).format || 'a5'] || {}).linesCount || 0), 0), bookFormat.pageSize, bookFormat.pagesCount, Math.ceil(messages.reduce((lines, message) => lines + ((message[(options || {}).format || 'a5'] || {}).linesCount || 0), 0) / bookFormat.pageSize / bookFormat.pagesCount));
                //
                //     let linesInCurrentPage = 0;
                //     let messagesInCurrentPage = [];
                //     const pages = [];
                //     messages.forEach((message, i) => {
                //         i === 0 && getMessageLines(message, messages[i - 1], messages[i + 1], options, true);
                //         if(linesInCurrentPage >= bookFormat.pageSize || i === (messages.length - 1)) {
                //             pages.push({
                //                 messages: messagesInCurrentPage,
                //                 count: messagesInCurrentPage.length,
                //             });
                //             linesInCurrentPage = 0;
                //             messagesInCurrentPage = [];
                //         } else {
                //             linesInCurrentPage += (message[(options || {}).format || 'a5'] || {}).linesCount || 0;
                //             messagesInCurrentPage.push(message);
                //         }
                //     });
                //     return Promise.resolve(divideByPages(pages));
                // }

                console.log('REAL CALCULATION');
                return getBookPages(messages, options, percentile => onProgress(percentile, 60)).then(divideByPages);
            });
    };
}

export function getBookPages(allMessages, options = { format: 'a5' }, onProgress = percentile => {}) {
    return new Promise((resolve, reject) => {
        const bookFormat = BOOK_CONSTANTS[(options || {}).format || 'a5'];
        const progressThreshold = 1000;

        let messagesInPage = [];
        let linesInCurrentPage = 0;

        const messagesWithLines = getSubmessages(allMessages, options);

        const percentile = 100 / (messagesWithLines.length / progressThreshold);

        const getBookPagesRecursive = (messages, pages = [], onProgress = () => {}, index = 0) => {
            return new Promise((resolveRecursive, rejectRecursive) => {
                // if last message or if lines is higher than max (in case of attachment message) => don't forget to add last page
                if(linesInCurrentPage >= bookFormat.pageSize /* || index + 1 === messages.length */) {
                    DISPLAY_LOG && console.log(`
                        Adding a new page with ${linesInCurrentPage} lines (>= ${bookFormat.pageSize}) // left ${messages.length - index} messages
                        ------
                    `);
                    DISPLAY_LOG && console.log(messagesInPage.reduce((total, message) => total + message.linesCount, 0), `${messagesInPage.length} messages`);

                    let nextPageMessages = [];
                    let nextPageLinesCountStart = 0;
                    if(linesInCurrentPage > bookFormat.pageSize) {
                        nextPageMessages = messagesInPage.splice(messagesInPage.length - 1, 1);
                        nextPageLinesCountStart = nextPageMessages.reduce((total, message) => total + message.linesCount, 0) + bookFormat.messageNameSize + bookFormat.messageWrapperSize + bookFormat.messageBubbleSize;
                        DISPLAY_LOG && console.log(`
                            ${nextPageLinesCountStart}
                        `, 'nextPageMessages', nextPageMessages);
                    }

                    // DISPLAY_LOG && console.log(`Page ${pages.length}:::  Last message of current page`, messagesInPage[messagesInPage.length - 1]);
                    // DISPLAY_LOG && nextPageLinesCountStart && console.log(`Page ${pages.length + 1}::: First message (reinjected)`, `${linesInCurrentPage} > ${bookFormat.pageSize}`, nextPageMessages, nextPageLinesCountStart);

                    pages.push({
                        count: messagesInPage.length,
                        messages: messagesInPage,
                    });

                    // Reset counters
                    messagesInPage = nextPageMessages;
                    linesInCurrentPage = 0 + nextPageLinesCountStart;
                }

                const message = messages[index];
                if(message) {
                    const prevMessage = messagesInPage.length ? (messages[index - 1] || null) : null;
                    const nextMessage = messages[index + 1] || null;

                    const messageLines = getMessageLines(
                        message,
                        prevMessage && prevMessage.id !== message.id ? prevMessage : null,
                        nextMessage && nextMessage.id !== message.id ? nextMessage : null,
                        options,
                        false && DISPLAY_LOG,
                    ); // Every sub message has a count of 1 line (empty or with text)
                    const messageLinesCount = messageLines.count;

                    DISPLAY_LOG && console.log(`Message N°${index}: ${linesInCurrentPage} lines in current page // ${messagesInPage.length} messages in page // ${messageLines.details.join(' + ')} = ${messageLines.count} ${message.text} // ${message.attachments}`);
                    // /!\ Already counted on getMesageLines func
                    // if(message.attachment) {
                    //     messageLinesCount += bookFormat.attachmentSize;
                    // }

                    if(messageLines.lines.filter(line => line).length || (message.attachments || []).length) {
                        linesInCurrentPage += messageLinesCount;
                        messagesInPage.push({
                            ...message,
                            linesCount: messageLinesCount,
                            detailsLines: process.env.NODE_ENV === 'development' ? messageLines.details : [],
                        });
                    }

                    index % progressThreshold === 0 && onProgress(percentile);

                    const recursive = () => resolveRecursive(getBookPagesRecursive(messages, pages, onProgress, index + 1));
                    // DO NOT use setTimeout if not needed (Because of 5s execution time by message !!!)
                    if(index && index % progressThreshold === 0) {
                        // Timeout to not freeze browser or server
                        console.log('Freeze::: 0.2s');
                        return setTimeout(recursive, 200);
                    }
                    return recursive();
                }

                DISPLAY_LOG && console.log(`
                    This was the last message => Add the last page !
                `);
                console.log(messagesInPage);
                pages.push({
                    count: messagesInPage.length,
                    messages: messagesInPage,
                });

                return resolveRecursive(pages);
            });
        };

        getBookPagesRecursive(messagesWithLines, [], onProgress).then(pages => {
            console.log(`${Math.ceil(pages.length / bookFormat.pagesCount)} books generated with ${pages.length} pages`);

            if(DISPLAY_LOG) {
                allMessages.forEach(message => {
                    if(!checkMessageIntegrity(message, pages)) {
                        console.error('Missing message part', message);
                        // return rejectRecursive(new Error('MissingMessagePart'));
                    }
                });
            }

            if(pages.length < 30) {
                const pagesCountToFill = 30 - pages.length;
                console.log('Fill with blank pages', pages.length, pagesCountToFill);
                for(let pageI = 0; pageI < pagesCountToFill; pageI += 1) {
                    pages.push({
                        count: 0,
                        messages: [],
                    });
                }
            }

            pages = pages.map((page, index) => {
                // console.log(`PAGE:::${index} Reset messages order`);
                const messages = [];
                // console.log(`${page.messages.length} messages in page`);
                page.messages.forEach((message, i) => {
                    if(message) {
                        messages[message.id] = {
                            ...message,
                            text: `${(messages[message.id] || {}).text ? `${(messages[message.id] || {}).text}\n` : ''}${message.text}`,
                            attachments: [...((messages[message.id] || {}).attachments || []), ...(message.attachments || [])],
                            linesCount: ((messages[message.id] || {}).linesCount || 0) + (message.linesCount || 0),
                        };
                        if(i === 0 || i + 1 === page.messages.length) {
                            // console.log(message.id, messages[message.id].text);
                        }
                    }
                });

                DISPLAY_LOG && console.log(`${messages.length} in page after reset (Reset of ${page.messages.length - messages.length})`);
                return {
                    ...page,
                    messages: messages.filter(message => message),
                };
            });

            // Set event pages count
            if((pages.length + BOOK_PAGES_EXTRA_COUNT) % 2 !== 0) {
                pages.push({ messages: [] });
            }
            resolve(pages);
        });
    });
}

function getSubmessages(messages, options = { format: 'a5' }) {
    const bookFormat = BOOK_CONSTANTS[(options || {}).format || 'a5'];
    let messagesWithLines = [];

    let i = 0;
    messages.forEach((message, messageIndex) => {
        const canAddAttachments = false;
        const messageLines = getMessageLines(message, messages[i - 1] || null, messages[i + 1] || null, options);
        let lineIndex = -1;
        messagesWithLines = messagesWithLines.concat(
            messageLines.lines.filter((line, lineI) => line /* || message.attachments.length === bookFormat.attachmentCountPerLine */ || lineI === 0).map(line => {
                // Only add attachments at last message line
                // if(false && message.attachments.length && (lineIndex + 1) === messageLines.lines.length) {
                //     canAddAttachments = true;
                // }
                if(line) {
                    lineIndex += 1;
                    return {
                        ...message,
                        text: line,
                        attachments: canAddAttachments ? (message.attachments || []).slice(0, bookFormat.attachmentCountPerLine) : [],
                        id: i,
                        lineIndex,
                    };
                }
                return null;
            }).filter(messageLine => messageLine),
        );
        if((message.attachments || []).length /* > bookFormat.attachmentCountPerLine */) {
            // console.log(`${bookFormat.attachmentCountPerLine} images / line`);
            // console.log('base attachments', message.attachments);
            for(let j = 0; j < Math.ceil((message.attachments || []).length / bookFormat.attachmentCountPerLine); j += 1) {
                i += 1;
                // console.log('Attachments iteration', j, j === 0 ? 0 : (j * bookFormat.attachmentCountPerLine), (j + 1) * bookFormat.attachmentCountPerLine, (message.attachments || []).slice(j === 0 ? 0 : (j * bookFormat.attachmentCountPerLine), (j + 1) * bookFormat.attachmentCountPerLine));
                // Separate multi attachments message in many messages
                const multipleAttachmentsSubMessage = {
                    ...message,
                    text: '',
                    attachments: (message.attachments || []).slice(j === 0 ? 0 : (j * bookFormat.attachmentCountPerLine), (j + 1) * bookFormat.attachmentCountPerLine),
                    id: i,
                    lineIndex: j,
                };
                messagesWithLines.push(multipleAttachmentsSubMessage);
            }
        }
        i += 1;
    });

    console.log(`${messagesWithLines.length} sub messages (with lines count)`);
    return messagesWithLines;
}

export function getConversationMessageText(message, remplaceBackSpace = true) {
    const text = message && message.text ? (remplaceBackSpace ? nl2br(message.text) : message.text) : '';
    try {
        return decodeURIComponent(text);
    } catch (err) {
        DISPLAY_LOG && console.error(err, message, message.text);
        return text;
    }
}

export function transformCsvToJson(csv, preserveLine = false) {
    return csv.split('\n').filter(line => line).map(line => {
        const [date, sender, recipient, text, isSender, attachmentsText, formatsLinesCount] = line.split(';');
        const data = { date, sender, recipient, text, isSender, attachments: getMessageAttachments(attachmentsText) };
        (formatsLinesCount || '').split('|').forEach(linesCount => {
            const [format, count] = linesCount.split(':');
            data[format] = { linesCount: parseInt(count, 10) };
        });
        if(preserveLine) {
            data.csv = line;
        }
        return data;
    });
}

export function getMessageLines(message, prevMessage, nextMessage, options = { format: 'a5' }, log = false) {
    const bookFormat = BOOK_CONSTANTS[(options || {}).format || 'a5'];
    let count = 0;
    const lines = [];
    const details = [];

    log && console.log(`

        -----

    `, message, prevMessage && 'has prev message', nextMessage && 'has next message');

    // Adding date title lines size
    if(!message.lineIndex && (!prevMessage || !prevMessage.date || (message.date && dateFormat(prevMessage.date, 'YYYYMMDD') !== dateFormat(message.date, 'YYYYMMDD')))) {
        log && console.log(`Add ${bookFormat.dateTitleSize} for date title`);
        count += bookFormat.dateTitleSize;
        details.push(`Title: ${bookFormat.dateTitleSize}`);
    // } else if(message.lineIndex === 0 && prevMessage && message) {
    //     console.log(message.text);
    //     console.log(`${dateFormat(prevMessage.date, 'YYYYMMDD')} !== ${dateFormat(message.date, 'YYYYMMDD')} ??`);
    }

    if(!message.lineIndex || (prevMessage && message.sender !== prevMessage.sender)) {
        log && console.log(`Add ${bookFormat.messageNameSize} for message name`);
        count += bookFormat.messageNameSize;
        details.push(`Name: ${bookFormat.messageNameSize}`);
    // } else {
    //     log && console.log(`Previous Message sender equal => Adding ${bookFormat.messageWrapperSize} lines`);
    //     count += bookFormat.messageWrapperSize;
    }

    if(!message.lineIndex && (!prevMessage || !nextMessage || prevMessage.sender !== message.sender || nextMessage.sender !== message.sender)) {
        log && console.log(`Add ${bookFormat.messageWrapperSize} for message wrapper`);
        count += bookFormat.messageWrapperSize;
        details.push(`Message wrapper: ${bookFormat.messageWrapperSize}`);
    }

    if(!message.lineIndex && message.text && (!nextMessage || (nextMessage && (!nextMessage.lineIndex || message.sender !== nextMessage.sender || message.attachments.length > 0)))) {
        log && console.log(`Add ${bookFormat.messageBubbleSize} for message lines`);
        count += bookFormat.messageBubbleSize;
        details.push(`Message bubble: ${bookFormat.messageBubbleSize}`);
    }

    const messageParts = getConversationMessageText(message, false).split('\n');

    // messageParts.length > 50 && console.log(getConversationMessageText(message), `${(getConversationMessageText(message).match(/\n/g) || []).length} retour(s) à la ligne`);

    messageParts.forEach(part => {
        // messageParts.length > 50 && console.log('Message part', part);
        let messageTextLeft = part;
        let trimmedString = '';
        let i = 0;
        const limit = 1000;

        if(messageTextLeft.length > bookFormat.messageCharsCount) {
            while(i <= limit && messageTextLeft.length > bookFormat.messageCharsCount) {
                if(i === limit) {
                    console.log('ERROR', getConversationMessageText(message, false), lines, messageTextLeft, trimmedString);
                    lines.push(messageTextLeft); // Add left messages to lines in case of error
                    notifyError(new Error('ExtremlyLongMessageError'));
                }

                if(messageTextLeft.length > trimmedString.length) {
                    // trim the string to the maximum length
                    trimmedString = messageTextLeft.substr(0, bookFormat.messageCharsCount);

                    // re-trim if we are in the middle of a word and
                    let nextTruncateIndex = Math.min(trimmedString.length, trimmedString.lastIndexOf(' '));
                    if(nextTruncateIndex <= 0) {
                        nextTruncateIndex = bookFormat.messageCharsCount;
                    }
                    trimmedString = trimmedString.substr(0, nextTruncateIndex);
                    lines.push(trimmedString);
                }
                messageTextLeft = messageTextLeft.substr(trimmedString.length, messageTextLeft.length);
                // messageParts.length > 50 && console.log('Message slice', trimmedString, messageTextLeft);

                i += 1;
            }
            lines.push(messageTextLeft);
        } else {
            lines.push(messageTextLeft);
        }
    });
    // lines.length > 50 && console.log(message, getConversationMessageText(message, false), `${(getConversationMessageText(message, false).match(/\n/g) || []).length} retour(s) à la ligne`, 'Large message', messageParts, lines);
    if(message.attachments.length > 0) {
        // count += bookFormat.attachmentSize * Math.ceil(message.attachments.length / bookFormat.attachmentCountPerLine);
        // log && console.log('bookFormat datas', bookFormat.attachmentSize, message.attachments.length, bookFormat.attachmentCountPerLine);
        const messageAttachmentsSize = bookFormat.attachmentSize * Math.ceil(message.attachments.length / bookFormat.attachmentCountPerLine);
        count += messageAttachmentsSize;
        // log && console.log('Adding attachments lines count', `${message.attachments.length} attachment(s)`, `+${bookFormat.attachmentSize * Math.ceil(message.attachments.length / bookFormat.attachmentCountPerLine)} lines`, lines);
        // log && /* message.attachments.length > 1 && */ console.log(message);

        details.push(`Message attachments: ${messageAttachmentsSize}`);
    }
    // log && console.log('getMesageLines', message, lines, count);
    // log && console.log(`${lines.length} + ${count} = ${lines.length + count} lignes occupées`);

    // V1
    // return lines
    //     .filter(line => line)
    //     .concat(new Array(Math.max(0, Math.floor(count))).fill(''));

    // V2
    // lines = lines.filter(line => line);
    log && console.log(`Message with ${lines.filter(line => line).length} text lines so (${lines.filter(line => line).length} * ${bookFormat.messageLineSize}) + ${count} design lines = ${Math.max(0, count + (lines.filter(line => line).length * bookFormat.messageLineSize))}`, `"${message.text}"`, lines);

    lines.filter(line => line).length && details.push(`Message lines: ${lines.filter(line => line).length * bookFormat.messageLineSize} (${lines.filter(line => line).length} * ${bookFormat.messageLineSize})`);

    log && console.log(details);
    return {
        lines,
        count: Math.max(0, Math.ceil(count + (lines.filter(line => line).length * bookFormat.messageLineSize))),
        details,
    };
}

export function getAttachmentUrlData(conversation, message, participant, attachment, isWeb = true) {
    let url = '';
    let type = '';
    if(conversation && message && attachment) {
        const linkRegex = new RegExp('http(s)?://');
        const imageRegex = new RegExp(`[.]{1}(jp(e)?g|png|webp${isWeb ? '|gif' : ''})+`);
        const isLink = linkRegex.test(attachment);

        if(!isLink && imageRegex.test(attachment)) { // Second check if media is image (can be displayed)
            url = `conversations/${conversation._id}/images/${attachment}`;
            type = 'image';
        } else { // Finally display QR Code to redirect to media page viewer
            let urlViewer = '';
            if(isLink) {
                type = 'link';
                urlViewer = attachment;
            } else {
                const { key, salt } = getHashKeys(`${conversation._id}.${attachment}`);
                const params = [
                    attachment,
                ].concat([
                        `${(conversation.cover || {}).from || ''} ${(conversation.cover || {}).to || ''}`,
                        participant ? (participant.displayName || participant.name) : '',
                        message.text || '',
                        message.date,
                    ]
                    .map(value => value.replace(/\//gi, '-'))
                    .map(value => value.replace(/\?/gi, '-'))
                    .map(value => value.replace(/#/gi, ''))).concat([
                    key,
                    salt,
                ]);
                const viewer = `${config.url}conversation/${conversation._id}/viewer/${params.join(MESSAGE_ATTACHMENT_VIEWER_DATA_SEPARATOR)}`;
                // console.log(`
                //     1: ${viewer}
                //     2: ${encodeURI(viewer)}
                //     3: ${encodeURIComponent(viewer)}
                //     4: ${encodeURIComponent(encodeURI(viewer))}
                //
                // `);
                process.env.NODE_ENV === 'development' && console.log('attachment viewer url', encodeURI(viewer), params);
                urlViewer = encodeURIComponent(encodeURI(viewer));
            }
            // const qrCodeApi = 'https://api.qrserver.com/v1/create-qr-code/';
            const qrCodeApi = `${config.url}api/file/qrcode`;
            const size = '100';
            const options = '&color=0c1e58'; // `&color=${textColor.replace('#', '')}&bgcolor=${bgColor.replace('#', '')}&margin=5`; // Use with caution due to bg color contrast
            url = `${qrCodeApi}?size=${size}x${size}${options}&data=${urlViewer}`;
            type = 'qrcode';
            false && process.env.NODE_ENV === 'development' && console.log(url);
        }
    }

    return {
        url,
        type,
    };
}

// DEBUG
export function checkMessageIntegrity(message, pages) {
    const functionLogDisplay = false && DISPLAY_LOG;
    const logs = [];

    logs.push(`checkMessageIntegrity / Pages length: ${pages.length}`);
    const spaceEncodedString = /(%(.){2}|\.|-)/;
    let pageIndex = pages.length - 1;
    let firstWordCheck = false;
    let lastWordCheck = false;

    logs.push(`Message initial: ${message.text}`);
    const messageWords = (message.text || '').split(spaceEncodedString);
    const firstWord = messageWords.shift();
    const lastWord = messageWords.pop();
    logs.push(`Message starts with "${firstWord}" and ends with "${lastWord}"`);

    if(firstWord && lastWord) {
        while(pageIndex >= 0 && (!firstWordCheck || !lastWordCheck)) {
            logs.push(`PageIndex: ${pageIndex}`);
            if(pages[pageIndex] && pages[pageIndex].messages && pages[pageIndex].messages.length) {
                // functionLogDisplay && console.log(pages[pageIndex].messages);

                // First word check
                if(pages[pageIndex].messages.some(subMessage => {
                    const subMessageText = subMessage.text.replace(spaceEncodedString, '');
                    logs.push(`Sub message for first word ("${firstWord}"): "${subMessage.text.substring(0, 10)}  ...  ${subMessage.text.substring(subMessage.text.length - 10, subMessage.text.length)}" / Index of firstWord: ${subMessage.text.indexOf(firstWord)}`);
                    return subMessageText.indexOf(firstWord) === 0;
                })) {
                    firstWordCheck = true; // Can be one a distinct page of lastWord
                }

                // Last word check
                if(pages[pageIndex].messages.some(subMessage => {
                    const subMessageText = subMessage.text.replace(spaceEncodedString, '');
                    logs.push(`Sub message for last word ("${lastWord}"): "${subMessage.text.substring(0, 10)}  ...  ${subMessage.text.substring(subMessage.text.length - 10, subMessage.text.length)}" / Index of lastWord: ${subMessageText.lastIndexOf(lastWord)}`);
                    return subMessageText.lastIndexOf(lastWord) === subMessageText.length - lastWord.length;
                })) {
                    lastWordCheck = true; // Can be one a distinct page of firstWord
                }
            } else {
                // console.log(`Page does not exist at index ${pageIndex}`, pages);
            }
            // functionLogDisplay && console.log('Decrement page index');
            // functionLogDisplay && console.log(`Loop again: ${pageIndex >= 0 && (!firstWordCheck || !lastWordCheck)} === ${pageIndex} >= 0 && (!${firstWordCheck} || !${lastWordCheck})`);
            pageIndex -= 1;
        }
        functionLogDisplay && console.log(`firstWordCheck: ${firstWordCheck}`);
        functionLogDisplay && console.log(`lastWordCheck: ${lastWordCheck}`);
        !(firstWordCheck && lastWordCheck) && console.error(`${firstWord} (${firstWordCheck}) ... ${lastWord} (${lastWordCheck})`, message, logs, pages.reduce((pl, p) => (`${pl} // ${p.messages.reduce((ml, m) => (`${ml} / ${(m || {}).text || ''}`), '')}`), ''));
        return firstWordCheck && lastWordCheck;
    }
    return true;
}
