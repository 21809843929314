import React from 'react';
import PropTypes from 'prop-types';

export default function PageTitle(props) {
    return (
        <div className="align-items-center mb-4 border-bottom">
            <h1 className="h3 mb-0 text-dark">{props.children}</h1>
        </div>
    );
}

PageTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
};
