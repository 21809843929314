/**
 * Client entry point
 */

import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';

import ReactGA4 from 'react-ga4';


import config from './config';
import App from './App';
import { configureStore } from './store';
import '../Intl/setup';

// PWA Offline support: https://github.com/NekR/offline-plugin
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import { setAppSSRMode } from './modules/App/AppActions';
process.env.NODE_ENV === 'production' && OfflinePluginRuntime.install();

if(typeof window !== 'undefined' /* && process.env.NODE_ENV === 'production'*/) {
    window.addEventListener('load', () => {
        console.log('Window::: Loaded');

        // GA4: New
        if(config.google.analytics.trackingCode) {
            config.google.analytics.trackingCode.split(',').forEach(trackingId => {
                ReactGA4.initialize(trackingId, {});
            });
            ReactGA4.ga('set', 'appName', config.application.name);
            ReactGA4.ga('set', 'appVersion', config.version);
        }
    });
}

// Initialize store
const store = configureStore(window.__INITIAL_STATE__);
// Allow the passed state to be garbage-collected
delete window.__INITIAL_STATE__

const container = document.getElementById('root');

// Set as not SSR
store.dispatch(setAppSSRMode(false))

ReactDOMClient.hydrateRoot(container, <App store={store} />);

// For hot reloading of react components
if(module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default; // eslint-disable-line global-require
        ReactDOMClient.hydrateRoot(container, <NextApp store={store} />);
    });
}
