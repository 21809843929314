import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import config from '../../config';

// Import Actions
import { getLoggedUser } from '../User/UserActions';
import { getConfig } from '../Config/ConfigActions';
import { initLanguage, getCountryFromLanguage } from '../Intl/IntlActions';

// Import Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import MaintenceModePage from './pages/view/MaintenceModePage';
import ToastProvider from '../Error/components/ToasterProvider';

// Import Style
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

export function App({ intl, isMounted = false, maintenanceMode = false, user = null }) {
    // const canMount = isMounted || !process.env.CLIENT;
    // const isDev = false;

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: `${intl.locale}-${getCountryFromLanguage(intl.locale)}`,
                    translate: 'no',
                }}
                defaultTitle={config.application.name}
                titleTemplate={`%s - ${config.application.name}`}
                meta={[
                    { charset: 'utf-8' },
                    {
                        'http-equiv': 'X-UA-Compatible',
                        content: 'IE=edge',
                    },
                    {
                        name: 'viewport',
                        content: 'width=device-width, initial-scale=1',
                    },
                    {
                        name: 'robots',
                        content: 'noindex',
                    },
                ]}
            />

            <ToastProvider>
                <div id="content-wrapper" className="app-wrapper d-flex flex-column">
                    <div id="content">
                        <Header />

                        <Container fluid className="content-wrapper p-0 p-md-2">
                            <div className="content my-3">
                                {maintenanceMode && (!user || user.role !== 'admin') ? <MaintenceModePage /> : <Outlet />}
                            </div>
                        </Container>

                        <Footer />
                    </div>
                </div>
            </ToastProvider>
        </>
    );
}

App.propTypes = {
  intl: PropTypes.object.isRequired,
  isMounted: PropTypes.bool.isRequired,
  user: PropTypes.object,
  maintenanceMode: PropTypes.bool,
};

function mapStateToProps(store, props) {
    return {
        isMounted: store.app.isMounted,
        user: getLoggedUser(store),
        maintenanceMode: !!getConfig(store, 'maintenance'),
    };
}

App.need = [(params, store, query) => { return initLanguage(params._lang || query.lang, null, store); }];

export default connect(mapStateToProps)(injectIntl(App));
