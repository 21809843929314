import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ListGroup, Button } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';

import { getConversations, mergeConversationsRequest } from '../../ConversationActions';
import { getLoggedUser } from '../../../User/UserActions';

import ConversationListItem from './ConversationListItem';
import ConversationTypeSelector from '../ConversationTypeSelector';
import { displayErrors } from '../../../Error/ErrorActions';

class ConversationList extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isMergeMode: false,
            isLoading: false,
            conversationsToMerge: [],
        };
    }

    handleAddConversationsToMerge = (conversation, checked = true) => {
        const newConversationsToMerge = this.state.conversationsToMerge.filter(conversationId => conversationId !== conversation._id);
        this.setState({
            conversationsToMerge: checked ? [...newConversationsToMerge, conversation._id] : newConversationsToMerge,
        });
    }

    handleMerge = event => {
        this.setState({
            isLoading: true,
        });
        this.props.dispatch(mergeConversationsRequest(this.state.conversationsToMerge)).then(isOk => {
            this.setState({
                isLoading: false,
                isMergeMode: false,
            });
            isOk && displayErrors('success', this.props.intl.formatMessage({ id: 'conversationMergeRequestSuccess', defaultMessage: 'Conversations will be merged soon! You will receive an email when merge is finished.' }));
        });
    }

    render() {
        const { conversations, count } = this.props;
        if(conversations.length) {
            return (
                <div className="text-center">
                    {
                        this.state.isMergeMode
                        ? <span className="d-inline-block mb-1"><FormattedMessage id="conversationMergeInfo" defaultMessage="Select conversations to be merged and click on 'Merge' button below" /></span>
                        : <Button color="link" size="sm" block className="text-right text-primary" onClick={event => this.setState({ isMergeMode: true })}><FontAwesome name="compress-arrows-alt" /> <FormattedMessage id="conversationMerge" defaultMessage="Merge conversations in one" /></Button>
                    }
                    {this.state.isMergeMode}
                    <ListGroup>
                        {conversations.filter(conversation => conversation._id).sort((convA, convB) => new Date(convA.start) - new Date(convB.start)).slice(0, count || conversations.length).map(conversation => (
                            <ConversationListItem key={conversation._id} conversation={conversation} onChangeSelection={this.state.isMergeMode && this.handleAddConversationsToMerge} />
                        ))}
                    </ListGroup>
                    {this.state.isMergeMode && <Button color="success" block disabled={this.state.conversationsToMerge.length < 2 || this.state.isLoading} onClick={this.handleMerge}>{this.state.isLoading ? <FontAwesome name="spinner" spin /> : <FontAwesome name="check-circle" />} <FormattedMessage id="conversationMerge" defaultMessage="Merge conversations" /></Button>}
                </div>
            );
        }
        return <ConversationTypeSelector />;
    }
}

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        conversations: getConversations(store, user),
    };
}

ConversationList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    conversations: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
};

export default connect(mapStateToProps)(injectIntl(ConversationList));
