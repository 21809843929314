// import JSZip from 'jszip';

// use glogal zip object from https://gildas-lormeau.github.io/zip.js/index.html

import { sequence } from './promises';
import { dataURItoBlob } from './image';
import config from '../config';

const GENERIC_FILE_EXCLUDES = [/__MACOSX/];
export const MEDIAS_EXTENSIONS = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'mp4', 'mp3', 'opus', 'aac', 'm4a'];

export function readArchive(file, options = { includeRegexFiles: [], excludeRegexFiles: [] }, onProgress = percentile => {}) {
    console.log('readArchive launched...', options);

    zip.workerScriptsPath = `${config.staticUrl}/assets/js/`;
    // const zip = new JSZip();
    onProgress(1);
    // return zip.loadAsync(file).then(zipContent => {
    return new Promise((resolve, reject) => {
        const reader = new zip.ZipReader(new zip.BlobReader(file));
        // zip.createReader(
        //   new zip.BlobReader(file),
          // reader => {
            reader.getEntries().then(entries => {
                // console.log('Archive::: entries', entries);
                entries = entries
                    .filter(entry => (options.includeRegexFiles || []).some(includeRegex => includeRegex.test(entry.filename)))
                    .filter(entry => (options.excludeRegexFiles || []).concat(GENERIC_FILE_EXCLUDES).every(excludeRegex => !excludeRegex.test(entry.filename)));

                // console.log('entries filtered', entries);
                const percentile = 100 / entries.length;

                getFileContentRecursive(entries, results => {
                    // console.log('readArchive ended');
                    resolve(results.map(entry => {
                        // console.log('Is entry blob', entry.path, entry, entry.type);
                        return {
                            ...entry,
                            filename: entry.path.split('/').pop(),
                        };
                    }));
                }, onProgress, reject);

                // resolve(sequence(entries, entry => {
                //     let type = 'text';
                //     const extension = zipContent.files[entryPath].name.split('.').pop();
                //     switch(extension) {
                //         case 'jpeg':
                //         case 'jpg':
                //         case 'png':
                //         case 'gif':
                //             type = 'image';
                //             break;
                //
                //         default:
                //             break;
                //     }
                //     console.log(zipContent.files[entryPath].name, extension, type);
                //     return zipContent.files[entryPath].async(type === 'image' ? 'blob' : 'text').then(content => {
                //         onProgress(percentile);
                //
                //         return {
                //             path: entryPath,
                //             filename: entryPath.split('/').pop(),
                //             content,
                //             type,
                //         };
                //     });
                // }).then(entries => {
                //     return entries;
                // }));
            });
        // }
    //     , reject);
    });
}

function getFileContentRecursive(entries, onSuccess = () => {}, onProgress = percentile => {}, onError = err => {}, index = 0, results = []) {
    const entry = entries[index];
    if(entry) {
        getFileContent(entry).then(data => {
            onProgress(100 / entries.length);
            getFileContentRecursive(entries, onSuccess, onProgress, onError, index + 1, [...results, data]);
        }).catch(err => {
            onError(err);
        });
    } else {
        onSuccess(results);
    }
}

function getFileContent(entry) {
    return new Promise((resolve, reject) => {
        try {
            const ext = ((entry.filename || '').split('.').pop() || '').toLowerCase();
            if(MEDIAS_EXTENSIONS.includes(ext)) {
                let type = '';
                switch(ext) {
                    case 'mp4':
                    case 'webm':
                    case 'mov':
                        type = 'video';
                        break;

                    case 'mp3':
                    case 'opus':
                    case 'aac':
                    case 'm4a':
                    case 'ogg':
                        type = 'audio';
                        break;

                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'gif':
                    case 'webp':
                    default:
                        type = 'image';
                }
                // console.log(`File content type: ${type}/${ext}`, entry);
                entry.getData(new zip.Data64URIWriter(`${type}/${ext.replace('jpeg', 'jpg')}`)).then(data => {
                    resolve({ path: entry.filename, content: dataURItoBlob(data), type });
                });
            } else {
                console.log(`Missing content type for ${ext}`, entry);
                entry.getData(new zip.TextWriter(null)).then(text => {
                    resolve({ path: entry.filename, content: text, type: 'text' });
                });
            }
        } catch (err) {
            reject(err);
        }
    });
}
