import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import CookieConsent from 'react-cookie-consent';

import config from '../../../../config';

import UserAdminLoginSwitcher from '../../../User/components/admin/UserAdminLoginSwitcher';

export function Footer() {
    const [version, setVersion] = useState('');

    useEffect(() => {
        setVersion(config.version);
    }, []);

    return (
        <footer className="footer sticky-footer text-gray-400">
            <Container className="my-auto">
                <p className="text-center">
                    <img src={`${config.staticUrl}assets/images/logo-monlivresms-2.png`} alt={config.application.name} height="100" /><br />
                </p>
                <p className="links text-center">
                    <a href="https://www.monlivresms.com/comment-imprimer-ses-messages-smartphone/" target="_blank" rel="noreferrer"><FormattedMessage id="appQuestionLink" defaultMessage="Need help?" /></a>{' '}
                    <a href="mailto:hello@monlivresms.com?subject=MonLivreSMS: " target="_blank" rel="noreferrer"><FormattedMessage id="contactButton" defaultMessage="Contact us" /></a>{' '}
                    <a href="https://www.monlivresms.com/conditions-generales-de-ventes/" target="_blank" rel="noreferrer"><FormattedMessage id="appConditionsAcceptTitle" defaultMessage="Sales conditions" /></a>
                </p>
                <div className="copyright text-center my-auto">
                    &copy; {new Date().getFullYear()} &middot; {config.application.name} <small>v.{version}</small> - <small>Made with <FontAwesome name="coffee" /> by <a href="https://offaxis.io" className="text-gray-600" target="_blank" rel="noreferrer">OFF<span style={{ color: '#D90000' }}>/</span>AXIS</a></small>
                </div>
                <UserAdminLoginSwitcher />
            </Container>
            <CookieConsent
                buttonText="OK"
                buttonClasses="btn btn-outline-primary btn-sm"
                buttonStyle={{}}
            >
                <FormattedMessage id="appCookieConsentText" defaultMessage="By continuing your visit to this site, you accept the use of cookies for the purposes of the functioning of the proposed service and statistics. No personal data will be communicated without your prior consent." />
                {' '}<Button size="sm" color="warning" outline tag="a" href="https://www.monlivresms.com/conditions-generales-de-ventes/" target="_blank"><FormattedMessage id="appCookieConsentPrivacyLink" defaultMessage="See our privacy policy" /></Button>
            </CookieConsent>
        </footer>
    );
}

export default Footer;
