import fetch from 'isomorphic-fetch';

import callApi, { callExternalApi } from './apiCaller';

export function getFileUrl(filename, userId = null, method = 'get') {
    return callApi('file/geturl', 'POST', { filename, userId, method }).then(result => {
        // console.log('getFileUrl::: result', result);
        return result.url;
    });
    // return new Promise((resolve, reject) => {
    //     getFileContent(filename, userId).then(result => {
    //         console.log(result);
    //         let fileUrl = '';
    //         if(result.blob) {
    //             return result.blob().then(blob => {
    //                 console.log(blob);
    //                 fileUrl = URL.createObjectURL(blob);
    //                 resolve(fileUrl);
    //             });
    //         } else if(result.url) {
    //             fileUrl = result.url;
    //             resolve(fileUrl);
    //         }
    //     }).catch(error => {
    //         console.error('getFileUrl', error);
    //         reject(error);
    //     });
    // });
}

export function getFileContent(filename, userId = null) {
    return getFileUrl(filename, userId).then(url => {
        if(url) {
            return fetch(url, {
                method: 'GET',
            })
            .then(response => {
                return response;
            });
        }
        return Promise.reject('noFileError');
    }).catch(error => {
        console.error('getFileContent', error);
    });
}

export function uploadFile(filename, data, contentType = '', userId = null) {
    return new Promise((resolve, reject) => {
        getFileUrl(filename, userId, 'put').then(url => {
            if(url) {
                fetch(url, {
                    headers: new Headers({ 'Content-Type': contentType }),
                    method: 'PUT',
                    body: data,
                })
                .then(result => {
                    resolve(true);
                })
                .catch(err => {
                    console.error('UploadFile:::Error on sending to S3', err);
                    reject(err);
                });
            } else {
                reject(new Error('noUploadUrl'));
            }
        }).catch(error => {
            console.error('UploadFile:::Error getting presigned url', error);
            reject(error);
        });
    });
}

export function getFiles(path) {
    return new Promise((resolve, reject) => {
    });
}

export function blobToFile(blob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    if(typeof blob === 'string') {
        blob = new Blob([blob]);
    }
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
}

export function getBlobContent(blob) {
    console.log(blob.type, blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // This fires after the blob has been read/loaded.
        reader.addEventListener('loadend', e => {
            return resolve(e.srcElement.result);
        });
        // Start reading the blob as text.
        reader.readAsText(blob);
    });
}
