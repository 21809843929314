import callApi from '../../util/apiCaller';
// import { addError } from '../Error/ErrorActions';

export const SET_CONTENT = 'SET_CONTENT';

export function getContentRequest(pageId, lang = 'fr') {
    return (dispatch) => {
        if(pageId) {
            return callApi(`cms/${lang}/${pageId}`).then(res => {
                if(res && res.content) {
                    dispatch(setContent(pageId, lang, res.title, res.content));
                }
                return res;
            }).catch(error => {
                // dispatch(addError(error));
            });
        }
    };
}

export function getPage(store, pageId, lang) {
    return store.cms.data.find(page => page.id === pageId && page.lang === lang);
}

export function setContent(pageId, lang, title, content) {
    return {
        type: SET_CONTENT,
        pageId,
        lang,
        title,
        content,
    };
}
