// Import Actions
import { SET_VISITOR, LOGIN_USER, LOGOUT_USER, /* CONNECTED_USER, CONNECTED_USERS, DISCONNECTED_USER, */ REGISTER_USER, SET_USERS, SET_USER_ACTIVITY, SET_USER_ACTIVITY_DATE } from './UserActions';
import { SET_IS_FETCHING } from '../App/AppActions';

import storage, { storagePersistent } from '../../util/storage';

// Initial State
export const initialState = {
    user: null,
    data: [],
    connectedUsers: {},
    isFetching: false,
    activityDates: {},
};

const UserReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case LOGIN_USER:
        case REGISTER_USER:
            // setSocketUser(action.user);
            return {
                ...state,
                user: { ...state.user, ...action.user, connected: 1 },
            };

        case LOGOUT_USER:
            return {
                ...state,
                user: null,
                data: [],
                connectedUsers: [],
            };

        // case CONNECTED_USER:
        //     return {
        //         ...state,
        //         connectedUsers: [...state.connectedUsers, action.user],
        //     };
        //
        // case CONNECTED_USERS:
        //     return {
        //         ...state,
        //         connectedUsers: action.users,
        //     };
        //
        // case DISCONNECTED_USER: {
        //     const newConnectedUsers = state.connectedUsers.filter(user => user._id !== action.user._id);
        //     return {
        //         ...state,
        //         connectedUsers: newConnectedUsers,
        //     };
        // }

        case SET_USERS:
            return {
                ...state,
                data: action.users,
                isFetching: false,
            };

        case SET_USER_ACTIVITY:
            return {
                ...state,
                connectedUsers: {
                    ...state.connectedUsers,
                    [action.userId]: action.lastActivityTimestamp,
                },
            };

        case SET_IS_FETCHING:
            if(action.dataType === 'users') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        case SET_USER_ACTIVITY_DATE:
            return {
                ...state,
                activityDates: {
                    ...state.activityDates,
                    [action.key]: action.value,
                },
            };

        default:
            return state;
    }
};

export default UserReducer;
