import callApi from '../../util/apiCaller';

import { addError } from '../Error/ErrorActions';

// Export Constants
export const SET_PROCESSES = 'SET_PROCESSES';


export function getProcessesRequest() {
    return dispatch => {
        return callApi('processes').then(result => {
            dispatch(setProcesses(result.processes));
            return result.processes;
        }).catch(err => {
            return null;
        });
    };
}

export function editProcessRequest(process) {
    return dispatch => {
        return callApi('process/edit', 'post', { process }).then(result => {
            dispatch(getProcessesRequest());
            return result.process;
        }).catch(err => {
            return null;
        });
    };
}

export function relaunchProcessRequest(processId) {
    return dispatch => {
        return callApi('process/relaunch', 'post', { process: { _id: processId } }).then(result => {
            dispatch(getProcessesRequest());
            return result.ok;
        }).catch(err => {
            return null;
        });
    };
}

// Getters
export function getProcesses(store) {
    return store.processes.data;
}

export function getProcess(store, id) {
    return store.processes.data.find(process => process._id === id);
}

// Export Actions
export function setProcesses(processes) {
    return {
        type: SET_PROCESSES,
        processes,
    };
}
