import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Navbar, Nav, NavbarBrand, NavbarToggler, NavItem, NavLink, Collapse, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import config from '../../../../config';

import { getAuthToken } from '../../../User/UserActions';
import { getOrdersHistoryLinkRequest } from '../../../Conversation/ConversationActions';

import withRouter from '../../../../components/Router/WithRouter';

const WEBSITE_SLUGS = {
    'fr': {
        'about-us': 'qui-sommes-nous',
        'prices': '#tarifs',
        'conversationInstagram': 'imprimer-ses-messages-instagram-livre',
        'conversationMessenger': 'imprimer-ses-messages-facebook-messenger-livre',
        'conversationWhatsapp': 'imprimer-ses-messages-whatsapp-dans-un-livre',
        'conversationAndroid': 'imprimer-ses-messages-android-dans-un-livre',
        'conversationIos': 'imprimer-ses-messages-iphone-dans-un-livre',
        'faq': 'comment-imprimer-ses-messages-smartphone',
        'book': 'le-livre',
        'blog': 'blog',
        // 'history': 'https://shop.monlivresms.com/fr/historique-commandes',
    },
    'en': {
        'about-us': 'en/who-we-are/',
        'prices': 'en/home-2/#tarifs',
        'conversationInstagram': 'en/how-to-print-messages-from-instagram/',
        'conversationMessenger': 'en/how-to-print-messages-from-facebook-messenger/',
        'conversationWhatsapp': 'en/how-to-print-his-whatsapp-messages/',
        'conversationAndroid': 'en/how-to-print-your-sms-from-an-android-phone/',
        'conversationIos': 'en/how-to-print-your-sms-from-an-iphone/',
        'faq': 'en/how-to-print-your-messages-from-a-smartphone/',
        'book': 'the-book',
        'blog': 'the-blog',
    },
    'de': {
        'about-us': 'en/who-we-are/',
        'prices': 'en/home-2/#tarifs',
        'conversationInstagram': 'en/how-to-print-messages-from-instagram/',
        'conversationMessenger': 'en/how-to-print-messages-from-facebook-messenger/',
        'conversationWhatsapp': 'en/how-to-print-his-whatsapp-messages/',
        'conversationAndroid': 'en/how-to-print-your-sms-from-an-android-phone/',
        'conversationIos': 'en/how-to-print-your-sms-from-an-iphone/',
        'faq': 'en/how-to-print-your-messages-from-a-smartphone/',
        'book': 'the-book',
        'blog': 'the-blog',
    },
    'es': {
        'about-us': 'es/quienes-somos/',
        'prices': 'es/pagina-principal/#tarifs',
        'conversationInstagram': 'es/imprime-tus-mensajes-de-instagram-en-un-libro/',
        'conversationMessenger': 'es/imprime-tus-mensajes-de-messenger-en-un-libro/',
        'conversationWhatsapp': 'es/imprime-tus-mensajes-de-whatsapp-en-un-libro/',
        'conversationAndroid': 'es/imprime-tus-mensajes-de-android-en-un-libro/',
        'conversationIos': 'es/imprime-tus-mensajes-de-iphone-en-un-libro/',
        'faq': 'es/preguntas-comunes/',
        'book': 'el-libro',
        'blog': 'blog',
    },
    'it': {
        'about-us': 'it/chi-siamo/',
        'prices': 'it/casa/#tarifs',
        'conversationInstagram': 'it/come-stampare-messaggi-da-instagram/',
        'conversationMessenger': 'it/come-stampare-messaggi-da-facebook-messenger/',
        'conversationWhatsapp': 'it/come-stampare-i-suoi-messaggi-whatsapp/',
        'conversationAndroid': 'it/come-stampare-il-tuo-sms-da-un-android/',
        'conversationIos': 'it/come-stampare-il-tuo-sms-da-un-iphone/',
        'faq': 'it/domande/',
        'book': 'il-libro',
        'blog': 'blog',
    },
    'pt': {
        'about-us': 'pt/quem-somos/',
        'prices': 'pt/homepage/#tarifs',
        'conversationInstagram': 'pt/imprimir-conversacao-mensagens-instagram-livro/',
        'conversationMessenger': 'pt/imprimir-conversacao-mensagens-facebook-messenger-livro/',
        'conversationWhatsapp': 'pt/imprimir-conversacao-mensagens-whatsapp-livro/',
        'conversationAndroid': 'pt/imprimir-conversacao-mensagens-android-livro/',
        'conversationIos': 'pt/imprimir-conversacao-mensagens-iphone-livro/',
        'faq': 'pt/questions-pt/',
        'book': 'o-livro',
        'blog': 'blogue',
    },
};

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    getWebsiteUrl(id) {
        // switch(id) {
        //     case 'history':
        //         return `${config.prestashop.urls.shop}/_login.php?lang=${this.props.intl.locale}&cart=123&access_token=${getAuthToken()}`;

        //     default:
        // }
        const slug = (WEBSITE_SLUGS[this.props.intl.locale] || WEBSITE_SLUGS.en || {})[id] || '';
        return slug.includes('http') ? slug : `${config.website.url}/${slug}`;
    }

    getRoutes() {
        return [
            {
                to: this.getWebsiteUrl('book'),
                name: 'appWebsiteBook',
                isExternal: true,
            },
            {
                to: this.getWebsiteUrl('faq'),
                name: 'appWebsiteFaq',
                isExternal: true,
            },
            {
                to: this.getWebsiteUrl('about-us'),
                name: 'appWebsiteAboutUs',
                isExternal: true,
            },
            {
                to: this.getWebsiteUrl('blog'),
                name: 'appWebsiteBlog',
                isExternal: true,
            },
            // {
            //     name: 'appWebsiteConversations',
            //     children: [
            //         {
            //             to: this.getWebsiteUrl('conversationInstagram'),
            //             name: 'appWebsiteConversationInstagram',
            //             isExternal: true,
            //         },
            //         {
            //             to: this.getWebsiteUrl('conversationMessenger'),
            //             name: 'appWebsiteConversationMessenger',
            //             isExternal: true,
            //         },
            //         {
            //             to: this.getWebsiteUrl('conversationWhatsapp'),
            //             name: 'appWebsiteConversationWhatsapp',
            //             isExternal: true,
            //         },
            //         {
            //             to: this.getWebsiteUrl('conversationAndroid'),
            //             name: 'appWebsiteConversationAndroid',
            //             isExternal: true,
            //         },
            //         {
            //             to: this.getWebsiteUrl('conversationIos'),
            //             name: 'appWebsiteConversationIos',
            //             isExternal: true,
            //         },
            //     ],
            // },
            {
                to: '/',
                name: 'conversationsMy',
                // picto: 'comment-dots',
            },
            {
                // to: this.getWebsiteUrl('history'),
                to: '#',
                onClick: this.handleGoToHistory,
                name: 'appShopOrderHistory',
            },
            // {
            //     to: `${config.prestashop.urls.shop}/${this.props.user ? this.props.user.preferences.language : 'fr'}/historique-des-commandes?access_token=${getAuthToken()}`,
            //     name: 'appShopOrderHistory',
            //     picto: 'history',
            //     isExternal: true,
            // },
            {
                name: 'admin',
                allowedRoles: ['admin'],
                children: [
                    {
                        to: '/console/conversations',
                        name: 'conversations',
                        // picto: 'comments',
                        allowedRoles: ['admin'],
                    },
                    {
                        to: '/console/users',
                        name: 'users',
                        // picto: 'users',
                        allowedRoles: ['admin'],
                    },
                    {
                        to: '/console/config',
                        name: 'configuration',
                        // picto: 'cogs',
                        allowedRoles: ['admin'],
                    },
                ],
            },
            // {
            //     type: 'title',
            //     name: 'administrationTitle',
            //     allowedRoles: ['admin'],
            // },
        ];
    }

    isRouteAllowed(route) {
        return (
            !route.allowedRoles
            || (this.props.user && this.props.user.role && route.allowedRoles.includes(this.props.user.role))
        );
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    handleGoToHistory = event => {
        event.preventDefault();
        getOrdersHistoryLinkRequest(this.props.intl.locale).then(url => {
            console.log(url);
            if(process.env.NODE_ENV === 'production' && url) {
                location.href = url;
            }
        });
    };

    renderRoutes(routes, level = 0) {
        return routes
            .filter(route => this.isRouteAllowed(route))
            .map((route, index) => {
                if(route.type === 'divider') {
                    return <NavItem key={index} className="p-2 sidebar-divider text-dark">|</NavItem>;
                } 
                if(route.type === 'title') {
                    return (
                        <NavItem key={index} className="d-block sidebar-heading">
                            <span className="p-2" style={{ position: 'absolute', top: '0' }}><FormattedMessage id={route.name} /></span>
                        </NavItem>
                    );
                }
                if((route.children || []).length) {
                    return (
                        <UncontrolledDropdown key={index}>
                            <DropdownToggle caret tag={Link} to="#" className="nav-link text-dark" onClick={event => event.preventDefault()}>
                                <FormattedMessage id={route.name} />
                            </DropdownToggle>
                            <DropdownMenu className="general-submenu">
                                {this.renderRoutes(route.children, level + 1)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                }
                return (
                    <NavItem key={index} tag={level ? 'span' : 'li'}>
                        {
                            route.isExternal
                            ? (
                                <a href={route.to} target="_blank" className="nav-link" rel="noreferrer">
                                    {route.picto && <span className="icon"><FontAwesome name={route.picto} size="lg" /></span>} <span className={`menuText p-1 text-${this.props.location.pathname === route.to ? 'primary' : ''}`}><FormattedMessage id={route.name} /></span>
                                </a>
                            ) : (
                                <Link to={route.to} onClick={route.onClick || (() => {})} className="nav-link">
                                    {route.picto && <span className="icon"><FontAwesome name={route.picto} size="lg" /></span>} <span className={`menuText p-1 text-${this.props.location.pathname === route.to ? 'primary' : ''}`}><FormattedMessage id={route.name} /></span>
                                </Link>
                            )
                        }
                    </NavItem>
                );
            }).filter(route => route);
    }

    render() {
        if(this.props.user) {
            return (
                <Nav navbar id="accordionSidebar">
                    {this.renderRoutes(this.getRoutes())}
                </Nav>
            );
        }
        return null;
    }

    // renderRoutes(routes, level = 0) {
    //     return routes.filter(route => this.isRouteAllowed(route)).map((route, index) => {
    //         return (
    //             <NavItem key={index} eventKey={route.to}>
    //                 <NavIcon>
    //                     <FontAwesome name={route.picto} size={!level ? '2x' : 'lg'} />
    //                 </NavIcon>
    //                 <NavText>
    //                     <FormattedMessage id={route.name} />
    //                 </NavText>
    //                 {route.routes && this.renderRoutes(route.routes, level + 1)}
    //             </NavItem>
    //         );
    //     });
    // }
    //
    // render() {
    //     const routes = this.getRoutes();
    //     return (
    //         <div className="menu-wrapper">
    //             <SideNav
    //                 onSelect={(selected) => {
    //                     // Add your code here
    //                     console.log('Menu selected', selected);
    //                     browserHistory.push(selected);
    //                 }}
    //             >
    //                 <SideNav.Toggle />
    //                 <SideNav.Nav defaultSelected={routes[0].to}>
    //                     {this.renderRoutes(routes)}
    //                 </SideNav.Nav>
    //             </SideNav>
    //         </div>
    //     );
    // }
}

function mapStateToProps(store) {
    return {
        user: store.users.user,
    };
}

Menu.propTypes = {
    location: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(injectIntl(Menu)));
