import callApi from '../../util/apiCaller';

import { localizationData, defaultLanguage, enabledLanguages } from '../../../Intl/setup';
import { getUserPreference, setUserPreferenceRequest } from '../User/UserActions';
import { setDateLanguage } from '../../util/date';

// Export Constants
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';

export function initLanguage(lang = null, user = null, store = null) {
    return dispatch => {
        if(!lang) {
            if(store && store.intl && store.intl.isInit) {
                lang = store.intl.locale;
            } else if(global.navigator && global.navigator.language) {
                const navigatorLanguage = global.navigator.language.split('-')[0];
                lang = navigatorLanguage;
            }
            // const initLocale = global.navigator && global.navigator.language || 'en';
        }
        if(enabledLanguages.indexOf(lang) === -1) {
            // console.log('lang not enabled!', lang);
            lang = defaultLanguage;
        }
        return callApi(`intl/language/${lang}`).then(res => {
            // console.log('intl/language/', lang, res);
            if(res) {
                dispatch(switchLanguage(lang, res));
                if(user && lang && localizationData[lang] && lang !== getUserPreference(user, 'language')) {
                    dispatch(setUserPreferenceRequest('language', lang));
                }
                // console.log('Lang set', lang);
                return lang;
            }
            return null;
        }).catch(err => {
            return null;
        });
    };
}

export function getEnabledLanguages() {
    return enabledLanguages;
}

export function getDefaultLanguages() {
    return defaultLanguage;
}

export function getCountryFromLanguage(lang) {
    switch(lang) {
        case 'en':
            return 'GB';

        default:
            return lang && lang.toUpperCase();
    }
}

export function getTranslation(model, field, lang) {
    if(lang !== 'fr' && model.translations) {
        const translation = model.translations.find(translation => translation.lang === lang);
        if(translation && translation[field]) {
            return translation[field];
        }
    }
    return model[field];
}

export function switchLanguage(newLang, data = {}) {
    console.log('switchLanguage', data);
    if(newLang && (data || localizationData[newLang])) {
        setDateLanguage(newLang);
        const messages = (data || localizationData[newLang] || {}).messages || {};

        return {
            type: SWITCH_LANGUAGE,
            locale: newLang,
            messages,
            isInit: true,
        };
    }
    return {
        type: SWITCH_LANGUAGE,
    };
}
