import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardTitle, CardBody, Alert, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import { getLoggedUser } from '../../modules/User/UserActions';

import withRouter from '../Router/WithRouter';
import Loader from '../Loader/Loader';
// import UserLoginPage from '../../modules/User/pages/UserLoginPage/UserLoginPage';
import ErrorPage from '../../modules/App/pages/error/ErrorPage';

export default function authorization(allowedRoles, authComponent) {
    return WrappedComponent => {
        class AuthorizationComponent extends Component {
            // componentDidMount() {
            //     if(!this.props.user) {
            //         this.context.router.push('/login');
            //     }
            // }
            //
            // componentWillUpdate(nextProps) {
            //     if(!nextProps.user) {
            //         this.context.router.push('/login');
            //     }
            // }

            isAuthenticated() {
                return this.props.user && allowedRoles && (!allowedRoles.length || allowedRoles.includes(this.props.user.role));
            }

            renderAuthenticatedContent() {
                return this.isAuthenticated()
                    ? <WrappedComponent {...this.props} />
                    : this.renderAlert();
            }

            renderAlert() {
                if(this.props.user) {
                    return <ErrorPage />;
                }
                console.log('Unauthorized', authComponent);
                return authComponent;
                // return <UserLoginPage location={this.props.location} redirect={this.props.location.pathname} />;
            }

            render() {
                // return <ComposedComponent {...this.props} />
                return (
                    this.props.isMounted
                    ? this.renderAuthenticatedContent()
                    : <Loader />
                );
            }
        }

        function mapStateToProps(store) {
            return {
                user: getLoggedUser(store),
                isMounted: store.app.isMounted,
            };
        }

        AuthorizationComponent.propTypes = {
            dispatch: PropTypes.func.isRequired,
            location: PropTypes.object.isRequired,
            user: PropTypes.object,
            isMounted: PropTypes.bool.isRequired,
        };

        return connect(mapStateToProps)(withRouter(AuthorizationComponent));
    };
}
