import { persistData, getPersistedData } from '../../util/persistor';

// Import Actions
import { SET_CONVERSATIONS, REMOVE_CONVERSATION, SET_CONVERSATION_MESSAGES, SET_BOOK_GENERATION, SET_TUTORIAL } from './ConversationActions';
import { SET_IS_FETCHING, INIT_PERSISTED_DATA } from '../App/AppActions';

// Initial State
export const initialState = {
    data: [],
    messages: {},
    bookGeneration: {},
    tutorials: {},
};

// Think to update reducers.js

const ConversationReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_CONVERSATIONS:
            return {
                ...state,
                data: action.isSet
                    ? action.conversations
                    : action.conversations.concat(state.data.filter(existingConversation => !action.conversations.find(newConversation => newConversation._id === existingConversation._id))),
            };

        case REMOVE_CONVERSATION:
            return {
                ...state,
                data: state.data.filter(existingConversation => action.conversationId !== existingConversation._id),
            };

        case SET_CONVERSATION_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.conversationId]: {
                        ...state.messages[action.conversationId],
                        [action.index]: action.messages,
                    },
                },
            };

        case SET_BOOK_GENERATION: {
            return {
                ...state,
                data: state.data.map(conversation => {
                    if(conversation._id === action.conversationId) {
                        return {
                            ...conversation,
                            books: action.books.map((book, index) => {
                                return {
                                    ...((conversation.books || [])[index] || {}),
                                    ...book,
                                    pages: [], // Do not save messages in DB
                                };
                            }),
                        };
                    }
                    return conversation;
                }),
            };
        }
        // case SET_BOOK_GENERATION: {
        //     const newBookGeneration = {
        //         ...(state.bookGeneration || {}),
        //         [action.conversationId]: {
        //             [action.format]: {
        //                 start: action.start,
        //                 end: action.end,
        //                 books: action.books,
        //                 format: action.format,
        //             },
        //         },
        //     };

        //     persistData('conversation.bookGeneration', newBookGeneration);
        //     return {
        //         ...state,
        //         bookGeneration: newBookGeneration,
        //     };
        // }

        case SET_TUTORIAL: {
            const newTutorials = {
                ...state.tutorials,
                [action.key]: action.value,
            };

            persistData('conversation.tutorials', newTutorials);
            return {
                ...state,
                tutorials: newTutorials,
            };
        }

        case SET_IS_FETCHING:
            if(action.dataType === 'conversations') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        case INIT_PERSISTED_DATA:
            return {
                ...initialState,
                // bookGeneration: getPersistedData('conversation.bookGeneration', {}),
                tutorials: getPersistedData('conversation.tutorials', {}),
            };

        default:
            return state;
    }
};

export default ConversationReducer;
