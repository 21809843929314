
// Import Actions
import { SET_CONTENT } from './CmsActions';

// Initial State
export const initialState = {
    data: [],
    // [
    //     {
    //         lang: 'fr',
    //         id: 'legal',
    //         title: 'legal mention',
    //         content: 'azerty'
    //     }
    // ]
};

const CmsReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_CONTENT:
            return {
                ...state,
                data: state.data
                    .filter(data => !(data.lang === action.lang && data.id === action.pageId))
                    .concat([
                        {
                            lang: action.lang,
                            id: action.pageId,
                            title: action.title,
                            content: action.content,
                        },
                    ]),
            };


        default:
            return state;
    }
};

/* Selectors */

// Export Reducer
export default CmsReducer;
