import { toast } from 'react-toastify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import config from '../../config';

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// Set in App.js
// const position = 'top-right';
// const effect = 'flip';
// const timeout = 3000;

export function displayErrors(type, message) {
    // console.log(type, message);
    // return {
    //     type: TRIGGER_ERROR,
    //     error: {
    //         type: type,
    //         message: message
    //     }
    // }
    message = message.message || message;
    if(message && typeof message === 'string') {
        switch(type) {
            case 'error':
                toast.error(message);
                break;

            case 'warning':
                toast.warning(message);
                break;

            case 'info':
                toast.info(message);
                break;

            case 'success':
                toast.success(message);
                break;

            default:
                toast(`${type} : ${message}`);
        }
    } else if(message !== 'Unauthorized') {
            console.error(message);
        } else {
            // console.log(message);
        }
}

export function confirmAction(title, message = '', confirm = () => {}, cancel = () => {}) {
    confirmAlert({
        title: title || 'Are you sure ?', // Title dialog
        message: message || 'Please confirm your action :', // Message dialog
        buttons: [
            {
                label: (cancel || {}).title || 'Cancel',
                onClick: (cancel || {}).action || cancel || (() => {}),
            },
            {
                label: (confirm || {}).title || 'Confirm',
                onClick: (confirm || {}).action || confirm || (() => {}),
            },
        ],
    });
}

export function sendNotification(message) {
    if(message && Notification && typeof Notification !== 'undefined') {
        if(Notification.permission === 'granted') {
            const myNotification = new Notification(config.application.name, {
                body: message,
            });
        } else if(Notification.permission !== 'denied') {
            Notification.requestPermission(permission => {
                // Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
                if(!('permission' in Notification)) {
                    Notification.permission = permission;
                }

                // Si l'utilisateur est OK, on crée une notification
                if(permission === 'granted') {
                    sendNotification(message);
                }
            });
        }
    }
}

export function addError(error, type = 'error') {
    return {
        type: ADD_ERROR,
        error: {
            message: error.message || error,
            type,
        },
    };
}

export function removeError(error) {
    return {
        type: REMOVE_ERROR,
        error,
    };
}
