import crypto from 'crypto';
import randomstring from 'randomstring';
import { getUsersRequest, isLoggedInRequest } from '../User/UserActions';
import { getConversationsRequest, getAllConversationsRequest } from '../Conversation/ConversationActions';
import { getPublicConfigRequest } from '../Config/ConfigActions';
import { initLanguage } from '../Intl/IntlActions';
import { addError } from '../Error/ErrorActions';

import config from '../../config';
import callApi from '../../util/apiCaller';
import { sequence } from '../../util/promises';
import { getCurrencyRatesRequest, initCurrency } from '../Currency/CurrencyActions';

// Export Constants
export const INIT_SOCKET = 'INIT_SOCKET';
export const SEND_SOCKET = 'SEND_SOCKET';
export const RECEIVE_SOCKET = 'RECEIVE_SOCKET';
export const SET_REDIRECT = 'SET_REDIRECT';
export const REDIRECT = 'REDIRECT';
export const SET_MOUNTED = 'SET_MOUNTED';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const INIT_PERSISTED_DATA = 'INIT_PERSISTED_DATA';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_SSR_MODE = 'SET_SSR_MODE';

export const FETCH_LIMIT = 500;

// Export Request Actions
export function initApp() {
    return dispatch => {
        console.log('initApp called');
        return dispatch(isLoggedInRequest()).then(user => {
            let initItems = [getPublicConfigRequest, getCurrencyRatesRequest];
            if(user) {
                switch(user.role) {
                    case 'admin':
                        // initItems = initItems.concat([getUsersRequest, getAllConversationsRequest]);
                        break;

                    case 'customer':
                    default:
                        initItems = initItems.concat([getConversationsRequest]);
                        break;
                }
                // return dispatch(getConversationsRequest()).then(() => {
                    // if(userRole === 'admin') {
                    //     return dispatch(getUsersRequest());
                    // }
                // });
            } else {
                initItems.push(initLanguage);
            }
            sequence(initItems, dispatch).then(results => {
                dispatch(setMounted());
            });
        });
    };
}

export function checkVersionRequest() {
    return dispatch => callApi('app/web/version').then(res => {
        if(res.version && res.version !== config.version && process.env.NODE_ENV !== 'development') {
            typeof window !== 'undefined' && window.location.reload();
        }
        return res.version;
    }).catch(error => {
        dispatch(addError(error));
    });
}

export function getLocalizationRequest() {
    return callApi('app/localization');
}

export function createIndexRequest() {
    return dispatch => callApi('app/database/index').then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
        });
}

// Export Actions

export function getTable(store, tableId) {
    return store.app.tables.find(table => table.id === tableId) || {};
}

export function isFetching(store, type) {
    return (store[type] || {}).isFetching || false;
}

export function initSocket(socket) {
    return {
        type: INIT_SOCKET,
        socket,
    };
}

export function sendSocket(datas) {
    // Secure subscribe socket with key
    if(datas && datas.type === 'subscribe' && typeof datas.data === 'object') {
        const salt = randomstring.generate();
        const keyCrypto = crypto.createHash('sha1');
        keyCrypto.update(`${process.env.NODE_ENV}${salt}${datas.data.channel}`);
        const key = keyCrypto.digest('hex');
        datas.data.salt = salt;
        datas.data.key = key;
    }
    return {
        type: SEND_SOCKET,
        data: datas,
    };
}

export function receiveSocket(datas) {
    return {
        type: RECEIVE_SOCKET,
        data: datas,
    };
}

export function subscribeToChannel(channel, addToChannelsList = true) {
    return dispatch => {
        dispatch(sendSocket({ type: 'subscribe', data: { channel } }));
        // addToChannelsList && dispatch(addChannelSubscription(channel));
    };
}

export function unsubscribeToChannel(channel, removeFromChannelsList = true) {
    return dispatch => {
        dispatch(sendSocket({ type: 'unsubscribe', data: { channel } }));
        // removeFromChannelsList && dispatch(removeChannelSubscription(channel));
    };
}

export function setRedirect(redirect = '') {
    // console.log('setRedirect', redirect);
    return {
        type: SET_REDIRECT,
        redirect,
    };
}

export function redirect() {
    return {
        type: REDIRECT,
    };
}

export function setMounted() {
    return {
        type: SET_MOUNTED,
    };
}

export function updateTable(tableId, tableData) {
    return {
        type: UPDATE_TABLE,
        tableId,
        tableData,
    };
}

export function initPersistedData() {
    return {
        type: INIT_PERSISTED_DATA,
    };
}

export function setIsFetching(dataType, isFetching = true) {
    return {
        type: SET_IS_FETCHING,
        dataType: dataType.toLowerCase(),
        isFetching,
    };
}

export function setAppSSRMode(isSSR) {
    return {
        type: SET_SSR_MODE,
        isSSR,
    };
}
