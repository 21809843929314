import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardTitle, CardBody, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import ReactGA4 from 'react-ga4';

import config from '../../../config';

import { getAvailableTypes } from '../ConversationActions';
import withRouter from '../../../components/Router/WithRouter';

function ConversationTypeSelector({ navigate }) {
    const [isSmartphone, setIsSmartphone] = useState(false);

    const smartphoneTypes = ['ios', 'android'];

    const handleClick = type => {
        navigate(`/conversation/${type}`);
        ReactGA4.event('select_content', {
            content_type: 'conversation',
            content_id: type,
        });
    };

    const renderSelector = (type, onClick = null) => (
        <Button color="link" as={Link} key={type} onClick={onClick || (() => handleClick(type))} className="text-dark">
            <span className="d-inline-block m-2 p-2"><img src={`${config.staticUrl}assets/images/${type}.png`} alt={type} className="w-50 w-md-75" /></span><br />
            <span className="mt-2 d-block">{type.toUpperCase()}</span>
        </Button>
        );

    return (
        <Card className="mb-3 shadow border-left-primary">
            <CardHeader>
                <CardTitle tag="h4"><FontAwesome name="plus" /> <FormattedMessage id="conversationCreateTitle" defaultMessage="Create a book" /></CardTitle>
            </CardHeader>
            <CardBody className="d-md-flex justify-content-md-around text-center">
                {
                    isSmartphone
                    ? smartphoneTypes.map(type => renderSelector(type))
                    : (
                        <Fragment>
                            {getAvailableTypes().filter(type => !smartphoneTypes.includes(type)).map(type => renderSelector(type))}
                            {renderSelector('sms', () => setIsSmartphone(true))}
                        </Fragment>
                    )
                }
            </CardBody>
            {isSmartphone && <Button color="link" block onClick={() => setIsSmartphone(false)}><FontAwesome name="chevron-left" /> <FormattedMessage id="back" defaultMessage="Back" /></Button>}
        </Card>
    );
}

ConversationTypeSelector.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default withRouter(ConversationTypeSelector);
