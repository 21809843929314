/**
 * Root Component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import IntlWrapper from './modules/Intl/IntlWrapper';
import ErrorBoundary from './components/Error/ErrorBoundary';
import BugPage from './modules/Error/pages/BugPage';

// Import Routes
import AppRoutes from './routes';

// Block react-intl errors
if(process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error;
    if(console.error === originalConsoleError) {
        console.error = (...args) => {
            if(`${args[0].message}`.indexOf('[@formatjs/intl') === 0) {
                return;
            }
            originalConsoleError.call(console, ...args);
        };
    }
}

export default function App({ store, location, context, ssr = false, key = 0, routerComponent = BrowserRouter }) {
    const Router = routerComponent || BrowserRouter;
    return (
        <Provider key={key || 1} store={store}>
            <IntlWrapper>
                <ErrorBoundary errorComponent={BugPage}>
                    <Router location={location} context={context}>
                        <AppRoutes ssr={ssr} />
                    </Router>
                </ErrorBoundary>
            </IntlWrapper>
        </Provider>
    );
}

App.propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    key: PropTypes.number,
    ssr: PropTypes.bool,
    routerComponent: PropTypes.element,
};
