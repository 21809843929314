// Import Actions
import { SET_PROCESSES } from './ProcessActions';

// Initial State
export const initialState = {
    data: [],
};

// Think to update reducers.js

const ProcessReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_PROCESSES:
            return {
                ...state,
                data: action.processes,
            };

        default:
            return state;
    }
};

export default ProcessReducer;
