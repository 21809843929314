import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ErrorBoundary } from 'react-error-boundary';

import config from '../../config';

let bugsnagClient = null;

const bugsnagComponent = ({ errorComponent, children }) => {
    return (
        <ErrorBoundary FallbackComponent={errorComponent} onError={notifyError}>
            {children}
        </ErrorBoundary>
    );
};

if(typeof window !== 'undefined' && config.bugsnag && config.bugsnag.key) {
    bugsnagClient = Bugsnag;

    Bugsnag.start({
        apiKey: config.bugsnag.key,
        appVersion: config.version,
        enabledReleaseStages: ['production'],
        appType: 'client',
        plugins: [new BugsnagPluginReact(React)],
    });
}

export function notifyError(error, meta = {}) {
    // if(process.env.NODE_ENV === 'production') {
        bugsnagClient ? bugsnagClient.notify(error, meta) : console.log(bugsnagClient);
    // }
    console.error(error, meta);
}

export default bugsnagComponent;
