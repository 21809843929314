import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, ListGroup, ListGroupItem, Button, Alert } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import moment from 'moment';

import { Line, Doughnut } from 'react-chartjs-2';

import { capitalizeFirstLetter, textToColor } from '../../../../util/text';
import { getPriceFromCent, getPriceCent } from '../../../../util/price';

import { getLoggedUser, getUsers } from '../../UserActions';
import { isFetching } from '../../../App/AppActions';
import withRouter from '../../../../components/Router/WithRouter';

class AdminDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.refreshInterval = null;

        this.intervalInMinutes = 20;

        this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidMount() {
        // if(!this.props.sessions.length || !this.props.invoices.length) {
        //     this.handleRefresh();
        // }

        this.refreshInterval = setInterval(this.handleRefresh, this.intervalInMinutes * 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    getMonthNumbers() {
        const monthCount = 12;
        const currentMonth = parseInt(moment.utc().format('MM'), 10);
        const monthNumbers = [];
        for(let i = monthCount - 1; i >= 0; i--) {
            monthNumbers.push(
                currentMonth - i > 0
                    ? currentMonth - i
                    : monthCount - Math.abs(currentMonth - i)
            );
        }
        return monthNumbers;
    }

    getMonthsLabels() {
        const monthsLabels = moment.months().map(monthName => capitalizeFirstLetter(monthName));
        return this.getMonthNumbers().map(number => monthsLabels[parseInt(number, 10) - 1]);
    }

    handleRefresh() {
        // this.props.dispatch(getSessionsRequest());
    }

    render() {
        const { user, users } = this.props;

        if(user) {
            return (
                <Fragment>
                </Fragment>
            );
        }
        return null;
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        users: getUsers(store),
        isFetching: {
            users: isFetching(store, 'users'),
        },
    };
}

AdminDashboard.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(injectIntl(AdminDashboard)));
