import moment from 'moment';
import { htmlToJson } from '@contentstack/json-rte-serializer';

import { readArchive } from '../../../util/archive';
import { sequence } from '../../../util/promises';

import { DATE_FORMAT_OUTPUT, MESSAGES_BLOCK_COUNT, MESSAGE_ATTACHMENTS_SEPARATOR } from '../ConversationActions';

// Read selected file by user
// For Messenger: User must select an archive
export function readFile(file, options, onProgress) {
    if(file.type.includes('html')) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                onProgress(100);
                // console.log(reader.result);
                resolve([
                    {
                        filename: file.name,
                        content: reader.result,
                    },
                ]);
            };
            reader.readAsText(file);
        });
    }
    return readArchive(file, options, onProgress);
}

export function getFiles(file, filesPaths = [], onProgress = () => {}) {
    return new Promise((resolve, reject) => {
        // console.log('FB getFiles', filesPaths);
        // console.log('local images', filesPaths.filter(imagePath => !/http(s)?:\/\//.test(imagePath)));
        // console.log('distant images', filesPaths.filter(imagePath => /http(s)?:\/\//.test(imagePath)));
        const localImages = filesPaths.filter(imagePath => !/http(s)?:\/\//.test(imagePath));
        readArchive(
            file,
            { includeRegexFiles: [
                ...localImages.map(imagePath => new RegExp(imagePath.split('/').pop(), 'gi')),
            ] },
            onProgress,
        ).then(localFiles => {
            console.log('Local files done', localFiles);

            return readArchive(
                file,
                { includeRegexFiles: filesPaths.filter(imagePath => /http(s)?:\/\//.test(imagePath)).map(imagePath => new RegExp(getMediaFileName(imagePath).split('.').shift(), 'gi')) },
                onProgress,
            ).then(distantFiles => {
                console.log('Distant files done', distantFiles);
                resolve([
                    ...(localFiles || []),
                    ...(distantFiles || []).map(distantImage => {
                        const ext = distantImage.filename.split('.').pop();
                        const filename = getMediaFileName(filesPaths.find(imagePath => {
                            const imagePathParts = distantImage.filename.split('_');
                            imagePathParts.pop();
                            return imagePath.includes(imagePathParts.join('_'));
                        }) || '');
                        return {
                            ...distantImage,
                            filename,
                        };
                    }),
                ]);
            }).catch(reject);
        }).catch(reject);
    });
}

export function canHandleImages(file) {
    return false;
}

function parseHtmlEntry(entry) {
    console.log('Snapchat::: parseHtmlEntry', entry.filename);
    const htmlDomBody = new DOMParser().parseFromString(entry.content, 'text/html').body;
    const data = htmlToJson(htmlDomBody);
    // console.log(data);
    return data;
}

function searchElementInHtml(htmlData, attributeSearch = '', attributeValueSearch = '', attributeTarget = '', options = { includes: false, allowMultiple: false }) {
    // console.log('1 - Search for', htmlData, attributeSearch, attributeValueSearch, attributeTarget, options.includes);
    const searchIn = elt => {
        // console.log('2 - Search in ', elt, typeof elt);
        if(!elt || (!Array.isArray(elt) && typeof elt !== 'object')) {
            return null;
        }
        const attributeTest = (!attributeSearch && !attributeValueSearch) || options.includes ? ((elt?.attrs || {})[attributeSearch] || '').includes(attributeValueSearch) : (elt?.attrs || {})[attributeSearch] === attributeValueSearch;
        if(attributeTest) {
            // console.log('4 - Attribute found', elt[attributeTarget]);
            return elt[attributeTarget];
        }
        // Array.isArray(elt)
        // ? console.log('3 - ', 'Search in sub array', elt)
        // : console.log('3 - ', 'Search in sub object', Object.keys(elt).map(key => elt[key]));

        return (
            elt.children
            ? elt.children.map(sub => searchIn(sub))
            : [] // Object.keys(elt).map(key => searchIn(elt[key])).filter(elt => elt)
        ).flat();
    };
    let r = searchIn(htmlData);
    if(!options.allowMultiple && Array.isArray(r)) {
        // console.log('Single value expected', r);
        r = r && r[0];
    }
    // console.log('X - Result', attributeSearch, r);
    return r;
}

function getThreadNameFromHtml(data) {
    const threadNamePrefix = 'Chat History with ';
    const wrapper = searchElementInHtml(data, 'class-name', 'rightpanel', 'children', { allowMultiple: true }) || [];
    const threadTitleBrut = (((wrapper[0]?.children[1] || {}).children || [])[0] || {}).text;
    !threadTitleBrut && console.log(data, threadTitleBrut);
    return (threadTitleBrut || '').replace(threadNamePrefix, '').trim();
    // return data?.html?.body?.div?.div[0]?.div?.div['#text'] || '';
}

function getMessagesListFromHtml(data, incluseService = false) {
    const messages = searchElementInHtml(data, 'class-name', 'rightpanel', 'children', { allowMultiple: true });
    return messages.map(messageHtml => {
        const wrapper = (messageHtml.children || [])[0];
        if(wrapper?.attrs?.orgType === 'span') {
            const dateBrut = (((wrapper.children[3] || {}).children || [])[0] || {}).text;
            if(dateBrut) {
                return {
                    content: ((((wrapper.children[2] || {}).children || [])[0] || {})).text || '',
                    date: dateBrut && moment.utc(dateBrut).format(),
                    from: ((((((((wrapper.children || [])[0] || {}).children || [])[0] || {}).children || [])[1] || {}).children || [])[0] || {}).text || '',
                    medias: [],
                };
            }
        }
        console.log('Missing sub span message wrapper', wrapper);
        return null;
    }).filter(message => message);

    // return (data?.html?.body?.div?.div[1]?.div?.div?.filter(messageWrapper => incluseService || !messageWrapper['@_class'].includes('service')) || [])
    //     .map(messageHtml => {
    //     const sender = searchElementInHtml(messageHtml, '@_class', 'from_name', '#text');
    //     prevSender = sender || prevSender;
    //     const message = {
    //         html: messageHtml,
    //         content: (searchElementInHtml(messageHtml, '@_class', 'text', '#text', { allowMultiple: true }) || []).join('\r\n') || '',
    //         date: moment.utc(searchElementInHtml(messageHtml, '@_class', 'date', '@_title', { includes: true }), 'DD.MM.YYYY HH:mm:ss').format().split('UTC').shift(),
    //         from: sender || prevSender || '',
    //         medias: getMessageMediasFromHtml(messageHtml) || [],
    //     };
    //     if(!message.from || !message.date) {
    //         console.log(message);
    //     }
    //     return message;
    // });
}

// function getMessageMediasFromHtml(messageHtml) {
//     const wrappers = searchElementInHtml(messageHtml, '@_class', 'media_wrap', 'a', { includes: true, allowMultiple: true });
//     return wrappers.map(wrapper => {
//         const file = searchElementInHtml(wrapper, '', '', '@_href');
//         if(!file) {
//             // console.log('Not a valid file', file, messageHtml, wrapper, links);
//             return null;
//         }
//         let type = '';
//         if(file.includes('video') || ['.mov'].includes((file || '').toLowerCase())) {
//             type = 'video';
//         } else if(file.includes('audio') || ['.ogg'].includes((file || '').toLowerCase())) {
//             type = 'audio';
//         } else if(file.includes('photo')) {
//             type = 'image';
//         } else {
//             console.log('Not a valid media', file);
//         }

//         return type && {
//             type,
//             file,
//         };
//     }).filter(media => media);
// }

// Retrieve profile name
export function getProfileName(entries) {
    const participants = [];
    entries.filter(entry => entry.filename.includes('.html')).forEach(entry => {
        const data = parseHtmlEntry(entry);
        const threadName = getThreadNameFromHtml(data);
        getMessagesListFromHtml(data).forEach(message => {
            // const messageSender = (message?.div[1]?.div[1] || [])['#text'] || '';
            const messageSender = message.from;
            if(messageSender && !participants.find(participant => participant === messageSender) && messageSender !== threadName) {
                participants.push(messageSender);
            }
        });
    });
    return decodeURIComponent(encodeURIComponent(participants && participants.length ? participants.shift() : ''));
}

// Retrieve threads names
// friendThread = {
//     id: '',
//     name: '',
//     count: 0,
// };
export function getThreads(entries) {
    let threads = [];

    entries.filter(entry => entry.filename.includes('.html')).forEach(entry => {
        console.log('Search threads in', entry);
        const data = parseHtmlEntry(entry);
        const name = getThreadNameFromHtml(data);
        if(name) {
            const count = getMessagesListFromHtml(data).length;
            if(threads.find(thread => thread.name === name)) {
                threads = threads.map(thread => {
                    if(thread.name === name) {
                        return {
                            ...thread,
                            count: thread.count + count,
                        };
                    }
                    return thread;
                });
            } else {
                threads.push({
                    id: name,
                    name,
                    count,
                });
            }
        }
    });
    console.log('Threads found', threads);
    return threads;
}

// Generate CSV Content
// Separate messages in distinct csv (10 000 messages / csv)
export function getArchiveContent(entries, from, thread, options = { withImages: false, withMedias: false }, onProgress = percentile => {}) {
    console.log(thread, from, options, entries);

    const participants = [];
    let messages = [];
    const messagesBlocks = [];
    let images = [];
    let medias = [];

    entries
    .filter(entry => entry.filename.includes('.html') && entry.filename.includes(thread.id))
    .forEach(entry => {
        onProgress(1); // Init progress

        // Need to escape unicode sequence before json parse
        const data = parseHtmlEntry(entry);
        messages = messages.concat(getMessagesListFromHtml(data));
    });

    messages = messages
        // .sort((a, b) => getMessageDateFromHtml(a) - getMessageDateFromHtml(b))
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((message, index) => {
            return {
                ...message,
                ...getMessageContent(message, from, thread.name, options),
            };
        })
        // .filter(({ messageMedias }) => {
        //     if(process.env.NODE_ENV === 'development') {
        //         return messageMedias.length && messageMedias.find(media => media.includes('.mp4'));
        //     }
        //     return true;
        // })
        .filter(messageData => {
            // !messageData.messageCsv && console.error('Message not valid', messageData);
            // messageData.messageImages.length && console.log(messageData.messageImages, entries, entries.some(entry => messageData.messageImages.some(imagePath => imagePath && `${entry.path}`.toLowerCase().includes(imagePath.toLowerCase()))));
            // @TODO: Check if image file is in entries (image entries)
            return messageData.messageCsv;
        });

    const iteration = Math.floor(messages.length / MESSAGES_BLOCK_COUNT);

    const percentile = 100 / (iteration + 1);

    console.log(`${messages.length} messages to check`, messages);

    for(let i = 0; i <= iteration; i += 1) {
        console.log(`Messages block process: ${i + 1}/${iteration + 1} (de ${i * MESSAGES_BLOCK_COUNT} à ${i * MESSAGES_BLOCK_COUNT + MESSAGES_BLOCK_COUNT})`);
        const messagesBlock = messages.slice(i * MESSAGES_BLOCK_COUNT, i * MESSAGES_BLOCK_COUNT + MESSAGES_BLOCK_COUNT);

        onProgress(percentile);

        let csv = '';
        messagesBlock.forEach((message, j) => { // eslint-disable-line
            const { messageParticipant, messageCsv, messageImages, messageMedias } = message;

            if(!participants.includes(messageParticipant)) {
                participants.push(messageParticipant);
            }
            csv += messageCsv;

            csv += '\n';
            images = images.concat(messageImages);
            medias = medias.concat(messageMedias);
        });

        if(messagesBlock[0]) {
            messagesBlocks.push({
                sort: i,
                filename: `messages_${i + 1}.csv`,
                content: csv,
                messages: messagesBlock,
                start: new Date(messagesBlock[0].date),
                end: new Date((messagesBlock[messagesBlock.length - 1] || messagesBlock[0]).date),
            });
        } else {
            console.error('Missing messages in block', messagesBlock, participants);
        }
    }

    return {
        participants,
        messagesBlocks,
        images,
        medias,
    };
}

function getMessageContent(message, from, to, options = { withImages: false, withMedias: false }) {
    let messageCsv = '';
    const medias = [];

    const messageImages = getMessageMedias(message, 'image');
    const messageMedias = getMessageMedias(message, ['audio', 'video']);
    // message.content = (message.content || '') + (imagesExtraContent || '') + (mediasExtraContent || '');

    if(options.withImages) {
        medias.push(...messageImages);
    }
    if(options.withMedias) {
        medias.push(...messageMedias);
    }

    // const senderName = getMessageSenderFromHtml(message);
    const senderName = message.from;
    if(!senderName) {
        console.log('Missing sender name', message);
        return null;
    }
    // const messageContent = getMessageTextFromHtml(message);
    const messageContent = message.content;
    !messageContent && console.log('Missing message content', senderName, messageContent, message);
    // console.log(messageContent);

    const data = {
        date: message.date && moment.utc(message.date).format(DATE_FORMAT_OUTPUT).split('+').shift(),
        sender: senderName,
        to: senderName === from ? to : from,
        content: messageContent ? encodeURIComponent(decodeURI(encodeURI(messageContent))) : '', // escape and decode unicode before encodeURIComponenent
        isSender: senderName === from ? 'true' : 'false',
        medias: (medias && medias.length ? medias.map(media => getMediaFileName(media)).join(MESSAGE_ATTACHMENTS_SEPARATOR) : ''),
    };

    messageCsv = message.content || medias.length ? `${Object.keys(data).map(key => data[key]).join(';')};` : '';

    return { messageParticipant: senderName, messageCsv, messageImages, messageMedias };
}

// Retrieve profile name
function getMessageMedias(message, types = null) {
    if(types && !Array.isArray(types)) {
        types = [types];
    }
    if(!types || !types.length) {
        types = null;
    }

    return message.medias.filter(media => !types || types.includes(media.type)).map(media => media.file);
}

function getMediaFileName(path) {
    return path.split('?').shift().split('/').pop();
}
