import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container, Jumbotron, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import config from '../../../../config';

import AppLogo from '../../components/AppLogo';
import Dashboard from '../../../User/components/Dashboard';

function MaintenceModePage(props) {
    return (
        <Container className="text-center">
            <AppLogo />
            <h2 className="m-3"><FormattedMessage id="appMaintenanceModeTitle" defaultMessage="We are on maintenance" /></h2>
            <p><FormattedMessage id="appMaintenanceModeText" defaultMessage="Wait for a while and come back later..." /></p>
        </Container>
    );
}

MaintenceModePage.propTypes = {
};

export default MaintenceModePage;
