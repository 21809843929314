import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { loginRequest, registerRequest, checkUserAccountRequest, askLostPasswordRequest, resetPasswordRequest, editPasswordRequest, login } from './UserActions';

export default class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPasswordRequired: false,
            user: null,
            email: '',
            password: '',
            confirmPassword: '',
            code: '',
            firstName: '',
            lastName: '',
            gender: 'female',
            userAttributes: null,
            errorFields: [],
        };

        this.hasErrorField = this.hasErrorField.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    handleChange(event) {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState(
            { [name]: value },
        );
    }

    hasErrorField(name) {
        return this.state.errorFields && this.state.errorFields.indexOf(name) !== -1;
    }

    checkEmailExists() {
        return this.props.dispatch(checkUserAccountRequest(this.state.email)).then(result => {
            return result;
        }).catch(err => {
            return null;
        });
    }

    handleLogin() {
        return this.props.dispatch(loginRequest(this.state.email.toLowerCase(), this.state.password)).then(result => {
            if(result && result.token) {
                this.props.dispatch(login(result.token));
                return true;
            }
            return false;
        }).catch(err => {
            console.error(err);
            return null;
        });
    }

    handleLoginWithToken(token) {
        this.props.dispatch(login(token));
        return true;
    }

    handleRegister(user) {
        return this.props.dispatch(registerRequest(user)).then(result => {
            if(result && result.user && result.token) {
                if(this.props.dispatch(login(result.token))) {
                    return result.user;
                }
                return null;
            }
            return null;
        });
    }

    handleChangePassword(oldPassword) {
        return this.props.dispatch(editPasswordRequest(oldPassword, this.state.password)).then(result => {
            return result;
        }).catch(err => {
            console.error(err);
            return { error: err };
        });
    }

    handleLostPassword() {
        return this.props.dispatch(askLostPasswordRequest(this.state.email));
    }

    handleResetPassword() {
        return this.props.dispatch(resetPasswordRequest(this.state.email.toLowerCase(), this.state.code, this.state.password));
    }

    handleConfirmPassword(confirmationCode) {
        return confirmPassword(this.state.email.toLowerCase(), confirmationCode, this.state.password).then(result => {
            // displayErrors('success', 'Your new password has been saved ! You can login with it');
            return result && this.handleLogin();
        }).catch(err => {
            console.error(err);
            return null;
        });
    }

    // handleRegister(user) {
    //     return this.props.dispatch(registerRequest(user)).then(result => {
    //         return result;
    //     }).catch(err => {
    //         console.error(err);
    //         return null;
    //     });
    // }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

User.propTypes = {
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.object,
};
