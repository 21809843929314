import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'reactstrap';

import { logoutUser, setAdminRequest } from '../../UserActions';
import User from '../../User';

class UserAdminLoginSwitcher extends User {

    constructor(props) {
        super(props);

        this.triggerLogin = this.triggerLogin.bind(this);
    }

    triggerLogin(userRole) {
        let email = '';
        switch(userRole) {
            case 'admin':
                email = 'clement@offaxis.io';
                break;

            default:
                email = 'test@offaxis.io';
                break;
        }
        this.setState({
            email,
            password: '123456',
        }, () => {
            this.props.dispatch(logoutUser(true));
            setTimeout(function() {
                this.handleLogin();
            }.bind(this), 500);
        });
    }

    render() {
        return (
            <div className={process.env.NODE_ENV === 'production' ? 'd-none' : ''}>
                <ButtonGroup className="w-100 mt-5">
                    <Button color="secondary" onClick={event => this.triggerLogin('customer')}>Customer</Button>
                    <Button color="danger" onClick={event => this.triggerLogin('admin')}>Admin</Button>
                </ButtonGroup>
                <Button onClick={event => this.props.dispatch(setAdminRequest())}>Create admin</Button>
            </div>
        );
    }
}

export default connect()(UserAdminLoginSwitcher);
