import { slugify } from './text';
import { storagePersistent } from './storage';

import config from '../config';

const KEY_PREFIX = `${slugify(config.application.name).toLowerCase()}.persistor.`;

export function persistData(key, data) {
    try {
        if(storagePersistent) {
            storagePersistent.setItem(`${KEY_PREFIX}${key}`, data ? JSON.stringify(data) : '');
        }
    } catch (err) {
        console.error(err);
    }
}

export function getPersistedData(key, defaultData = {}) {
    try {
        if(storagePersistent) {
            try {
                const value = storagePersistent.getItem(`${KEY_PREFIX}${key}`);
                const data = value && JSON.parse(value);
                return data || defaultData;
            } catch (e) {
                return defaultData;
            }
        }
        return defaultData;
    } catch (err) {
        console.error(err);
        return null;
    }
}
