import moment from 'moment';

import { readArchive, MEDIAS_EXTENSIONS } from '../../../util/archive';
import { sequence } from '../../../util/promises';
import { normalizeEmoji, removeEmojis } from '../../../util/text';

import { getMessageAttachmentsByType, DATE_FORMAT_OUTPUT, MESSAGES_BLOCK_COUNT, MESSAGE_SYSTEM_SENDER_NAME, MESSAGE_ATTACHMENTS_SEPARATOR } from '../ConversationActions';

const SYSTEM_MESSAGES = [
    '.vcf',
    'Les messages envoyés dans cette discussion et les appels sont désormais protégés avec le chiffrement de bout en bout.',
    'Les messages envoyés dans ce groupe sont désormais protégés avec le chiffrement de bout en bout.',
    'image absente',
    'vidéo absente',
    '<video omitted>',
    '<Media omitted>',
    '<Médias omis>',
    // '.opus',
    '<immagine omessa>',
    '<video omesso>',
    'I messaggi in questo gruppo sono protetti con la crittografia end-to-end.',
    'a créé ce groupe',
    'vous a ajouté(e)',
    'Messages to this chat and calls are now secured with end-to-end encryption',
    'Reacted ❤️ to your message',
];

const MESSAGE_REGEXES = { // ALL
    // start: /(\[)?([0-9]{1,2}[\/\.-][0-9]{1,2}[\/\.-][0-9]{2,4})(,?\s?à?\s?)([0-9]{1,2}[:\.][0-9]{1,2}[:\.]?[0-9]{0,2}[:]?)\s?([\]-])?/,
    // image: /(\[)?([0-9]{1,2}[\/\.-][0-9]{1,2}[\/\.-][0-9]{2,4})(,?\s?à?\s?)([0-9]{1,2}[:\.][0-9]{1,2}[:\.]?[0-9]{0,2}[:]?)\s?([\]-])?( )([^:]*)(:)( )(.*)( <.*)/,
    text: /(\[)?([0-9]{1,4}[\/\.-][0-9]{1,2}[\/\.-][0-9]{2,4})(,?\s?à?\s?)([0-9]{1,2}[:\.][0-9]{1,2}[:\.]?[0-9]{0,2}[:]?)\s?((A|P|M|\.|\s)*)?([\]-])?( )(([^:]*):\s)?(.*)/i,
    position: {
        date: 2,
        hour: 4,
        period: 5,
        name: 10,
        content: 11,
    },
};

// a tester
// (\[)?([0-9]{1,2}[\/\.-][0-9]{1,2}[\/\.-][0-9]{2,4})(,?\s?à?\s?)([0-9]{1,2}[:\.][0-9]{1,2}[:\.]?[0-9]{0,2}[:]?)\s?([\]-])?( )([^:]*)(:)( )(.*\n[^\[].*)*
const dateSeparators = ['/', '-', '.'];
const timeSeparators = [':'];
const partSeparators = ['', ','];
const timezoneSets = ['', 'A'];
const secondSets = ['', 'ss'];

const DATE_FORMATS_AVAILABLE = [];
timezoneSets.forEach(timezoneSet => {
    dateSeparators.forEach(dateSeparator => {
        timeSeparators.forEach(timeSeparator => {
            partSeparators.forEach(partSeparator => {
                secondSets.forEach(secondSet => {
                    const seconds = `${secondSet ? timeSeparator : ''}${secondSet}`;
                    const timezone = `${timezoneSet ? ' ' : ''}${timezoneSet}`;
                    DATE_FORMATS_AVAILABLE.push(
                        `DD${dateSeparator}MM${dateSeparator}YY${partSeparator} H${timeSeparator}mm${seconds}${timezone}`,
                        `DD${dateSeparator}MM${dateSeparator}YY${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                        `DD${dateSeparator}MM${dateSeparator}YYYY${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                        `M${dateSeparator}D${dateSeparator}YY${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                        `MM${dateSeparator}DD${dateSeparator}YYYY${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                        `MM${dateSeparator}DD${dateSeparator}YY${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                        `YYYY${dateSeparator}MM${dateSeparator}DD${partSeparator} HH${timeSeparator}mm${seconds}${timezone}`,
                    );
                });
            });
        });
    });
});
// console.log('DATE_FORMATS_AVAILABLE', DATE_FORMATS_AVAILABLE);
// const DATE_FORMATS_AVAILABLE = [
//     'M/D/YY HH:mm:ss',
//     'M/D/YY HH:mm',
//     'DD/MM/YYYY, HH:mm:ss', // FR
//     'DD/MM/YYYY, HH:mm', // FR
//     'MM/DD/YYYY, HH:mm:ss', // US
//     'MM/DD/YYYY, HH:mm', // US
//     'YYYY-MM-DD HH:mm:ss',
//     'YYYY-MM-DD HH:mm',
//     'DD/MM/YYYY HH:mm:ss', // FR
//     'DD/MM/YYYY HH:mm', // FR
//     'MM/DD/YY, HH:mm:ss A', // US
//     'MM/DD/YY, HH:mm A', // US
//     'M/D/YY, HH:mm:ss A',
//     'M/D/YY, HH:mm A',
//     'YYYY-MM-DD, HH:mm:ss A',
//     'YYYY-MM-DD, HH:mm A',
//     'DD/M/YY, H:mm:ss A',
//     'DD/M/YY, H:mm A',
//     'DD/MM/YY, H:mm:ss A',
//     'DD/MM/YY, H:mm A',
//     'YYYY.MM.DD HH:mm:ss',
//     'YYYY.MM.DD HH:mm',
//     'DD.MM.YY, HH:mm:ss',
//     'DD.MM.YY, HH:mm',
// ];

// Read selected file by user
// For Messenger: User must select an archive
export function readFile(file, options, onProgress) {
    if(file.type.includes('text')) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                onProgress(100);
                resolve([
                    {
                        path: file.name,
                        content: transformEntryToJSON(reader.result),
                    },
                ]);
            };
            reader.readAsText(file);
        });
    }
    return readArchive(file, options, onProgress).then(entries => {
        return entries.map(entry => {
            return {
                ...entry,
                content: transformEntryToJSON(entry.content),
            };
        });
    });
}

// Retrieve all images in archive
export function getFiles(file, imagesPaths = [], onProgress = () => {}) {
    if(file.type.includes('zip') || file.name.includes('zip')) {
        // console.log('WhatsApp::: getFiles', file, imagesPaths);
        return readArchive(
            file,
            { includeRegexFiles: imagesPaths.map(imagePath => new RegExp(imagePath, 'gi')) },
            onProgress,
        );
    }
    return Promise.resolve([]);
}

export function canHandleImages(file) {
    return !['text', 'json'].some(type => file.type.includes(type));
}

// Retrieve profile name
export function getProfileName(entries) {
    let participants = [];
    entries.forEach(entry => {
        const conversation = JSON.parse(entry.content);
        participants = participants.concat(conversation.participants);
    });
    // return decodeURIComponent(escape(normalizeEmoji(participants[0] || '')));
    return escape(normalizeEmoji(participants[0] || ''));
}

export function getThreads(entries) {
    return [];
}

// Generate CSV Content
// Separate messages in distinct csv (10 000 messages / csv)
export function getArchiveContent(entries, from, thread, options = { withImages: false, withMedias: false }, onProgress = percentile => {}) {
    console.log('getArchiveContent', from, thread, entries, options);

    const participants = [];
    let messages = [];
    const messagesBlocks = [];
    let images = [];
    let medias = [];

    entries.forEach(entry => {
        onProgress(1); // Init progress
        const thread = JSON.parse(entry.content);
        messages = messages.concat(thread.messages);
        thread.participants.forEach(participant => {
            if(!participants.includes(participant)) {
                participants.push(participant);
            }
        });
    });

    const messagesCount = messages.length;
    messages = getFormattedMessages(messages, participants, options);

    console.log('Messages selection', `${messages.length}/${messagesCount}`);

    const iteration = Math.floor(messages.length / MESSAGES_BLOCK_COUNT);

    const percentile = 100 / (iteration + 1);

    for(let i = 0; i <= iteration; i += 1) {
        console.log(`Messages block process: ${i + 1}/${iteration + 1}`);
        const messagesBlock = messages.splice(0, MESSAGES_BLOCK_COUNT);

        onProgress(percentile);

        let csv = '';
        messagesBlock.forEach(message => { // eslint-disable-line
            const { messageParticipant, messageCsv, messageImages, messageMedias } = message;
            csv += messageCsv;
            images = images.concat(messageImages);
            medias = medias.concat(messageMedias);
        });
        if(messagesBlock[0]) {
            messagesBlocks.push({
                sort: i,
                filename: `messages_${i + 1}.csv`,
                content: csv,
                messages: messagesBlock,
                start: moment(messagesBlock[0].date).toDate(),
                end: moment((messagesBlock[messagesBlock.length - 1] || messagesBlock[0]).date).toDate(),
            });
        }
    }

    return {
        participants,
        messagesBlocks,
        images,
        medias,
    };
}

function transformEntryToJSON(entryContent) {
    const participants = [];
    const messages = [];

    // const regex = new RegExp(MESSAGE_REGEXES.text, 'g');
    // const entryLines = [...entryContent.matchAll(regex)].map(match => match[0]);
    let entryLines = (entryContent || '').split('\n');
    if(entryLines.length === 1) {
        entryLines = (entryContent || '').split('<br>');
    }
    // console.log(entryLines);
    entryLines
        .filter(line => {
            const isIncluded = !isSystemMessage(line);
            !isIncluded && console.error('System Message', line, isSystemMessage(line));
            return isIncluded;
        })
        .forEach(line => {
            const message = parseLine(line);
            // console.log(message);
            if(message.date && message.sender && (message.text || message.attachments.length)) {
                if(!participants.includes(message.sender)) {
                    participants.push(message.sender);
                }
                messages.push(message);
            } else {
                // Handle multi lines messages
                const lastMessage = messages[messages.length - 1];
                if(lastMessage) {
                    if(lastMessage.text !== null) {
                        lastMessage.text += `\r\n${line}`;
                    } else {
                        messages.push({
                            date: lastMessage.date,
                            name: lastMessage.name,
                            message: line,
                            attachments: null,
                        });
                    }
                }
            }
        });
    console.log(messages);
    // console.log(getFormattedMessages(messages, []));

    return JSON.stringify({
        participants,
        messages,
    });
}

function parseLine(line) {
    let message = {};

    const matches = MESSAGE_REGEXES.text.exec(line);
    // console.log('parseLine', line, matches);
    if(matches) {
        const date = `${matches[MESSAGE_REGEXES.position.date]} ${matches[MESSAGE_REGEXES.position.hour]}`;
        const name = matches[MESSAGE_REGEXES.position.name];
        const content = matches[MESSAGE_REGEXES.position.content];
        // console.table([date, name, content]);

        if(date && content && !isSystemMessage(content)) {
            message = {
                date: getFormattedDate(date, (matches[MESSAGE_REGEXES.position.period] || '').replaceAll('.', '').replaceAll(' ', '').toUpperCase() === 'PM'),
                sender: name || MESSAGE_SYSTEM_SENDER_NAME,
                text: '',
            };
            if(MEDIAS_EXTENSIONS.some(ext => line.includes(`.${ext}`))) {
                // const attachmentName = line.substring(line.indexOf('<') + 1, line.indexOf('>')).trim();
                // const attachmentSplitted = attachmentName.split(' ');
                const attachmentSplitted = removeEmojis(content).split(' ');
                const attachments = attachmentSplitted.map(text => text.replace('<', '').replace('>', '')).filter(text => MEDIAS_EXTENSIONS.some(ext => text.endsWith(`.${ext}`)));
                message.attachments = attachments;
            } else {
                message.text = content;
            }
        } else {
            console.error('Message excluded', line, matches, date, name, content);
        }
    }

    return message;
}

function getMessageContent(message, participants, options = { withImages: false, withMedias: false }) {
    let messageCsv = '';
    messageCsv = `${moment(message.date).format(DATE_FORMAT_OUTPUT).split('+').shift()};`;
    messageCsv += `${message.sender};`;
    messageCsv += `${participants.length > 2 ? participants[0] : participants.indexOf(message.sender) > 0 ? participants[0] : participants[1]};`;
    messageCsv += `${encodeURIComponent(message.text)};`; // escape and decode unicode before encodeURIComponenent
    messageCsv += 'true;'; // always true for whatsapp
    messageCsv += message.attachments && (options.withImages || options.withMedias)
        ? `${getMessageAttachmentsByType(
                message.attachments,
                []
                    .concat(options.withImages ? ['image'] : [])
                    .concat(options.withMedias ? ['audio', 'video'] : []),
            ).join(MESSAGE_ATTACHMENTS_SEPARATOR)};`
        : '';
    messageCsv += '\n';

    return { messageParticipant: message.sender, messageCsv, messageImages: options.withImages ? getMessageAttachmentsByType(message.attachments, 'image') : [], messageMedias: options.withMedias ? getMessageAttachmentsByType(message.attachments, ['audio', 'video']) : [] };
}

function getFormattedMessages(messages, participants = [], options = {}) {
    return messages
        .sort((a, b) => parseInt(moment(a.date).format('X'), 10) - parseInt(moment(b.date).format('X'), 10))
        .filter(message => {
            if(message.text) {
                return true;
            }

            let shouldAdd = false;
            if((message.attachments || []).length) {
                if(options.withImages) {
                    shouldAdd = shouldAdd || getMessageAttachmentsByType(message.attachments, 'image').length;
                }
                if(options.withMedias) {
                    shouldAdd = shouldAdd || getMessageAttachmentsByType(message.attachments, ['audio', 'video']).length;
                }
            }
            return shouldAdd;
        })
        .map(message => {
            return {
                ...message,
                ...getMessageContent(message, participants, options),
            };
        })
        .filter(messageData => messageData.messageCsv);
}

function getFormattedDate(date, addHalfDay = false) {
    let formattedDate = null;
    DATE_FORMATS_AVAILABLE.forEach(format => {
        if(!formattedDate) {
            if(moment(date, format, true).isValid()) {
                formattedDate = moment(date, format);
                // console.log(formattedDate.toString());
            }
        }
    });
    !formattedDate && console.error('Format Date not valid', date);
    return moment((formattedDate || moment(date)).add(addHalfDay ? 12 : 0, 'hour').valueOf()).format(DATE_FORMAT_OUTPUT).split('+').shift();
}

function isSystemMessage(line) {
    return SYSTEM_MESSAGES.some(systemMessage => {
        const regex = new RegExp(systemMessage, 'g');
        return line.includes(systemMessage) || regex.test(line);
    }) || line.indexOf('.vcf') >= 0;
}
