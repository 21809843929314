import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import { textToColor } from '../../../../util/text';

import styles from './UserProfileImage.css';

class UserProfileImage extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    getUserId() {
        return this.props.user._id || this.props.user;
    }

    getUniqueId() {
        return `tooltip-${this.getUserId()}-${Math.floor((Math.random() * 1000) + 1)}`;
    }

    render() {
        if(this.props.user) {
            const uniqueId = this.getUniqueId();
            const userStyle = {
                background: textToColor(this.getUserId()),
                visibility: this.props.isHidden ? 'hidden' : 'visible',
            };
            return (
                <Fragment>
                    <span id={uniqueId} className={styles.userProfileImage} style={userStyle}>
                        {this.props.user.firstName ? this.props.user.firstName.charAt(0) : ' '}
                    </span>
                </Fragment>
            );
        }
        return null;
    }
}

// <UncontrolledTooltip target={uniqueId}>
//     {this.props.user.firstName || '_'}
// </UncontrolledTooltip>

UserProfileImage.propTypes = {
    user: PropTypes.object.isRequired,
    isHidden: PropTypes.bool,
};

export default UserProfileImage;
