import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../config';

function AppLogo(props) {
    return (
        <picture>
            <img src={`${config.staticUrl}assets/images/logo-monlivresms-2.png`} alt={config.application.name} width="150" {...props} />
        </picture>
    );
}

AppLogo.defaultProps = {
    suffix: '',
};

AppLogo.propTypes = {
    suffix: PropTypes.string,
};

export default AppLogo;
