import es6Promise from 'es6-promise';
import fetch from 'isomorphic-fetch';
import config from '../config';
import storage, { storagePersistent } from './storage';

import { notifyError } from '../components/Error/ErrorBoundary';

import { getAuthToken } from '../modules/User/UserActions';

es6Promise.polyfill();

// export const API_URL = (typeof window === 'undefined' || process.env.NODE_ENV === 'development')
//     ? process.env.BASE_URL || (`http://localhost:${config.port}/api`)
//     : '/api';

// export const API_URL = `${config.api}/api`;
export const API_URL = `${config.api}`;

// console.log(config, process.env, API_URL);

const MAX_ATTEMPTS = 3;

export default function callApi(endpoint, method = 'get', body = null, headersContent = {}, options = {}, attempts = 0) {
    const headers = new Headers(headersContent || {});
    if(!options.useRawBody && !headers.get('Content-Type')) {
        headers.append('Content-Type', 'application/json');
    }
    const isJSON = headers.get('Content-Type') === 'application/json';

    if(!options.isExternal) {
        const authToken = getAuthToken();
        if(authToken) {
            headers.append('Authorization', `Bearer ${authToken}`);
        }
    }

    const apiUrl = options.isExternal ? endpoint : `${API_URL}/${endpoint}`;

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                const fetchOptions = {
                    headers,
                    method,
                };
                if(!['GET', 'HEAD'].includes(method.toUpperCase()) && body) {
                    fetchOptions.body = options.useRawBody ? body : JSON.stringify(body);
                }
                fetch(apiUrl, fetchOptions)
                .then(response => {
                    if(response) {
                        return (options.isRaw ? Promise.resolve(response.body) : response.json())
                            .then(result => ({ result, response }))
                            .catch(err => {
                                console.error(err);
                                notifyCallApiError(err || new Error('JsonParseError'), apiUrl, method, body, headersContent, options, attempts);
                                reject(response.statusText || new Error('JsonParseError'));
                            });
                    }
                    notifyCallApiError(new Error('FetchApiError'), apiUrl, method, body, headersContent, options, attempts);
                    return reject(new Error('FetchApiError'));
                })
                .then(({ result, response }) => {
                    if(!response.ok) {
                        console.error('API Json Response Error', isJSON, body, result, response);
                        // return reject(json.message);
                        return reject(result);
                    }
                    // console.log('API Json Response', result, response);
                    return options.isRaw ? response : result;
                })
                .then(
                    response => resolve(response),
                    err => reject(err),
                ).catch(err => {
                    console.error(err);
                    reject(new Error('FetchApiError'));
                    notifyCallApiError(err, apiUrl, method, body, headersContent, options, attempts);
                    return err;
                });
            } catch (err) {
                console.error('FetchApiError', err);
                notifyCallApiError(err, apiUrl, method, body, headersContent, options, attempts);
                reject(new Error('FetchApiError'));
            }
        }, 1000 * attempts);
    });
}

export function callExternalApi(endpoint, method = 'get', body = null, headersContent = {}, options = { isExternal: false }) {
    return callApi(endpoint, method, body, headersContent, { ...options, isExternal: false }, true);
}

export function buildQuery(obj, parentKey = null, numPrefix = null) {
    const outputString = [];

    Object.keys(obj).forEach(val => {
        let key = val;

        if(numPrefix && !Number.isNaN(key)) {
            key = numPrefix + key;
        }

        key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
        if(parentKey) {
            key = `${parentKey}[${key}]`;
        }

        if(typeof obj[val] === 'object') {
            const query = buildQuery(obj[val], key, null);
            outputString.push(query);
        } else {
            const value = typeof obj[val] === 'string' ? encodeURIComponent(obj[val].replace(/[!'()*]/g, escape)) : obj[val];
            outputString.push(`${key}=${value}`);
        }
    });

    return outputString.join('&');
}

function isAuthFail(json) {
    const authFailRegex = /(TokenExpired|InvalidTokenUse|TokenNotFound|NoAccessToken|Unauthorized)/gi;
    return authFailRegex.test(json.name) || authFailRegex.test(json.message);
}

function notifyCallApiError(err, endpoint, method, body, headersContent, isExternal, attempts) {
    console.log('Notify Error', err, endpoint);
    notifyError(err, { severity: 'error', request: { endpoint, method, body, headersContent, isExternal, attempts } });
}
