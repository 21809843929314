import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CountFormat from '../../../../components/Text/CountFormat';

function IntlCountFormat({ value, locale, ...props }) {
    const getDecimalSeparator = () => {
        switch(locale) {
            case 'en':
                return '.';

            default:
                return ',';
        }
    };

    const getThousandsSeparator = () => {
        switch(locale) {
            case 'en':
                return ',';

            default:
                return ' ';
        }
    };

    return (
        <CountFormat value={value} decimalSeparator={getDecimalSeparator()} thousandsSeparator={getThousandsSeparator()} />
    );
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
    };
}

IntlCountFormat.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    locale: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(IntlCountFormat);
